/* RegiaoModal.module.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    position: relative;
  }
  
  .closeModalButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .closeModalButton:hover {
    background: #d32f2f;
  }
  
  .header {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .fieldset {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 4px;
  }
  
  .label {
    font-size: 14px;
    margin-bottom: 8px;
    color: #555;
  }
  
  .input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 95%;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .cancelButton {
    background: #ccc;
    color: #333;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancelButton:hover {
    background: #bbb;
  }
  
  .saveButton {
    background: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .saveButton:hover {
    background: #45a049;
  }
  