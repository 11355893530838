.toolbarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15%;
  right: 15px;
  z-index: 1100;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  gap: 10px; /* Espaçamento entre os botões */
  transition: all 0.3s ease;
}

.singleButton {
  width: 50px; /* Largura do contêiner com um botão */
  height: 50px; /* Altura do contêiner com um botão */
  padding: 0; /* Remove padding para centralizar melhor */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Deixa o contêiner circular para visual mais agradável */
}

/* Adicionando responsividade */
@media (max-width: 768px) {
  .toolbarContainer {
    top: 20px;
    right: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
  }

  .singleButton {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 480px) {
  .toolbarContainer {
    padding: 8px;
    right: 10px;
    bottom: 10px;
  }

  .iconButton {
    font-size: 20px;
  }

  .singleButton {
    width: 40px;
    height: 40px;
  }
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, color 0.3s;
}

.iconButton:hover {
  transform: scale(1.2);
}

.active {
  color: #000;
}
