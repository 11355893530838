/* src/components/FaturamentoModal/FaturamentoModal.module.css */

.overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    z-index: 1100;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formGroup input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .formActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .saveButton {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancelButton {
    background-color: #f0f0f0;
    color: #333;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  