/* src/pages/Postes/PhotoModal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  width: 80%; /* 80% da largura da tela */
  height: 80%; /* 80% da altura da tela */
  border-radius: 12px;
  overflow-y: auto;
  position: relative;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #ff4c4c; /* Cor destacada ao passar o mouse */
}

.photosContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px; /* Espaçamento acima das fotos */
}

.photoItem {
  flex: 1 1 200px;
  max-width: 250px; /* Limita o tamanho máximo de cada item */
  text-align: center;
}

.photoItem h4 {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
  font-weight: bold;
}

.photo {
  width: 100%;
  height: auto;
  max-width: 250px; /* Limita o tamanho da imagem */
  border-radius: 8px; /* Borda levemente arredondada */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.photo:hover {
  transform: scale(1.05); /* Efeito de zoom ao passar o mouse */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Realce na sombra */
}

.uploadButton {
  margin-top: 10px;
  background-color: #007bff; /* Azul padrão */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.uploadButton:hover {
  background-color: #0056b3; /* Azul mais escuro ao hover */
}
