.window {
  position: absolute;
  top: 40px;
  left: 20px;
  width: 320px;
  background: #fff;
  padding: 20px 25px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2000;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.deviceEUI {
  font-size: 0.9rem;
  color: #555;
  margin-left: 8px;
}

.form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.selectionInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 6px 10px;
}

.selectionInfo p {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}

.cancelSelectButton {
  background: #e74c3c;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
}

.cancelSelectButton:hover {
  background: #c0392b;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mapSelectButton {
  background: #007bff; /* Fundo azul */
  border: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapSelectButton.active {
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.searchInput {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50%;
}

.posteList {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.posteItem {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.posteItem:last-child {
  border-bottom: none;
}

.posteItem:hover,
.posteItem.selected {
  background-color: #f0f0f0;
}

.checkIcon {
  margin-left: 8px;
  color: green;
}

.errorMessage {
  color: red;
  font-size: 0.9rem;
}

.submitButton {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background-color: #0056b3;
}

.lastUpdate {
  font-size: 0.85rem;
  color: #333;
  margin-bottom: 10px;
}
