/* src/pages/OrdensServicos/DocumentoOrdemServico.module.css */

.documento {
  width: 210mm;
  min-height: 297mm;
  margin: 0 auto;
  padding: 10mm;
  background: #fff;
  font-family: Arial, sans-serif;
  color: #000;
  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid #ddd;
}

.pageContainer {
  min-height: 277mm;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5mm;
  page-break-after: avoid;
}

.headerContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo {
  width: 40px;
  height: auto;
}

.printButton {
  background: #007bff;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}

.printButton:hover {
  background: #0056b3;
}

h1 {
  font-size: 18px;
  margin: 0;
  text-align: center;
  color: #333;
}

.divider {
  border: 0;
  border-top: 1px solid #000;
  margin: 5px 0;
}

.section {
  margin-bottom: 5mm;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4mm;
  page-break-inside: avoid;
  background: #f8f9fa;
}

.section h2 {
  font-size: 14px;
  margin: 0 0 4mm 0;
  border-bottom: 1px solid #000;
  padding-bottom: 2mm;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}

.table td {
  padding: 5px;
  border: 1px solid #000;
  line-height: 1.3;
  word-break: break-word;
}

.table td:first-child {
  width: 35%;
  background: #e9ecef;
  font-weight: bold;
}

/* Redução da altura dos campos de observação e materiais */
.mediumTextBox,
.smallTextBox {
  border: 1px solid #000;
  padding: 3mm;
  background: #fff;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.mediumTextBox {
  min-height: 20mm; /* Reduzido pela metade */
  margin-bottom: 3mm;
}

.smallTextBox {
  min-height: 10mm; /* Reduzido pela metade */
}

/* Ajuste nos campos de assinatura e data/hora */
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 5mm;
  page-break-before: avoid;
  position: absolute;
  bottom: 10mm;
  width: 100%;
}

.signatureContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10mm;
}

.signature {
  width: 45%;
  text-align: center;
}

.signatureLine {
  border-bottom: 1px solid #000;
  margin-top: 8mm;
}

@media print {
  body {
    margin: 0;
    -webkit-print-color-adjust: exact;
  }

  .documento {
    padding: 5mm;
    width: 100%;
    min-height: auto;
  }

  .printButton {
    display: none !important;
  }

  .section {
    background: #fff !important;
    padding: 3mm !important;
  }

  .table td {
    padding: 4px !important;
    font-size: 11px !important;
  }

  h1 {
    font-size: 16px !important;
  }

  .section h2 {
    font-size: 12px !important;
  }

  .mediumTextBox {
    min-height: 15mm; /* Ajustado para impressão */
  }

  .smallTextBox {
    min-height: 8mm; /* Ajustado para impressão */
  }

  .footer {
    position: fixed;
    bottom: 5mm;
  }

  .signatureLine {
    margin-top: 6mm;
  }
}
