/* src/components/PosteGrupos/GrupoModal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Garante que fique acima de todos os outros elementos */
}

.modalContent {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.modalContent h2 {
  margin-top: 0;
  text-align: center;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.formGroup label {
  font-size: 14px;
  color: #555;
}

.input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 95%;
}

.colorPickerContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.colorDisplay {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.colorDisplay:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.colorInput {
  display: none; /* Esconde o input de cor padrão */
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.saveButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 14px;
}

.saveButton:hover {
  background-color: #45a049;
}

.cancelButton {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 14px;
}

.cancelButton:hover {
  background-color: #d32f2f;
}
