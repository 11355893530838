/* src/components/PasswordModal/PasswordModal.module.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .modal {
    background: white;
    padding: 20px 30px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    position: relative;
    box-sizing: border-box;
    max-height: 90vh;
    overflow-y: auto; /* Adiciona scroll caso o conteúdo exceda a altura */
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #7f8c8d;
  }
  
  .closeButton:hover {
    color: #2c3e50;
  }
  
  .modalContent {
    margin-top: 20px;
  }
  
  .modalContent h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .formGroup label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #34495e;
  }
  
  .formGroup input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .formGroup input:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  .formActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .saveButton {
    background-color: #4CAF50; /* Verde */
    color: white;
    padding: 8px 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saveButton:hover {
    background-color: #45a049;
  }
  
  .cancelButton {
    background-color: #e74c3c; /* Vermelho */
    color: white;
    padding: 8px 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cancelButton:hover {
    background-color: #c0392b;
  }
  
  .error {
    color: #e74c3c;
    font-weight: bold;
    text-align: center;
  }
  