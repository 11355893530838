/* src/pages/PerfisAcesso/PerfisAcesso.module.css */

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .controlsTop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .addButton, .exportButton {
    background-color: #4CAF50; /* Verde */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .addButton:hover, .exportButton:hover {
    background-color: #45a049;
  }
  
  .controls {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .searchContainer {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .searchIcon {
    position: relative;
    left: 10px;
    color: #555;
  }
  
  .searchInput {
    padding: 8px 8px 8px 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 250px;
  }
  
  .searchButton {
    padding: 8px 16px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .searchButton:hover {
    background-color: #2980b9;
  }
  
  .tableContainer {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 12px 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f2f2f2;
    cursor: pointer;
    position: relative;
  }
  
  .table th:hover {
    background-color: #e0e0e0;
  }
  
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tr:hover {
    background-color: #ddd;
  }
  
  .editButton {
    background-color: #008CBA; /* Azul */
    color: white;
    padding: 6px 12px;
    margin: 0 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
  }
  
  .editButton:hover {
    opacity: 0.8;
  }
  
  .paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination a {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .pagination a:hover {
    background-color: #f2f2f2;
  }
  
  .paginationActive a {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
  }
  
  .paginationDisabled a {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .noData {
    text-align: center;
    padding: 20px;
    color: #555;
    font-style: italic;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left: 10px;
  }
  
  .checkboxLabel input {
    margin-right: 5px;
  }
  