/* Container Principal */
.legendContainer {
  position: absolute;
  bottom: 20px;
  right: 10px;
  background: linear-gradient(135deg, #ffffff, #f7f7f7);
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  z-index: 1500;
  max-width: 300px;
  transition: all 0.3s ease;
}

.legendContainer.compact {
  max-width: none;
  width: auto;
  white-space: nowrap;
  overflow-x: auto;
  padding: 4px 10px;
}

/* Cabeçalho */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.legendTitle {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
  font-weight: 600;
}

.totalCount {
  font-size: 1rem;
  color: #555;
  margin-left: 8px;
}

.toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #555;
  transition: color 0.2s;
}

.toggleButton:hover {
  color: #007bff;
}

/* Botão de Resetar */
.resetButton {
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.9rem;
  color: #0056b3;
  cursor: pointer;
  margin-bottom: 12px;
  transition: background-color 0.2s;
}

.resetButton:hover {
  background-color: #0056b3;
  color: #cce5ff;
}

/* Lista Expandida */
.legendList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.legendItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: background-color 0.2s, transform 0.1s;
  cursor: pointer;
}

.legendItem:hover {
  background-color: #e9ecef;
  transform: scale(1.01);
}

/* Itens inativos */
.inactive {
  opacity: 0.6;
  border: 1px dashed #aaa;
}

.iconTextWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.legendIcon {
  width: 26px;
  height: 26px;
  object-fit: contain;
  margin-right: 10px;
}

.legendText {
  font-size: 0.95rem;
  flex-grow: 1;
}

.legendCount {
  font-weight: 600;
  font-size: 0.95rem;
  margin-left: 8px;
}

/* Lista Compacta */
.legendCompactList {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 12px;
  overflow-x: auto;
}

.legendCompactItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 4px;
}

.legendCompactItem:hover {
  transform: none;
}

.legendIconCompact {
  width: 34px;
  height: 34px;
  margin-bottom: 4px;
}

.legendCountCompact {
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
}

/* Botão de Informação */
.infoButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-left: 8px;
  transition: color 0.2s;
}

.infoButton:hover {
  color: #cce5ff;
}

/* Versão Tablet: telas entre 769px e 1024px */
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .legendContainer.compact {
    white-space: normal;
    padding: 8px;
  }
  .legendCompactList {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    overflow-x: visible;
  }
  .legendCompactItem {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 6px;
  }
  .legendIconCompact {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    margin-bottom: 0;
  }
  .legendCountCompact {
    font-size: 0.56rem;
    margin-left: 3px;
  }
  .legendTitle {
    font-size: 0.77rem;
  }
  .resetButton {
    padding: 5px 8px;
    font-size: 0.63rem;
  }
}

/* Versão Mobile: telas até 768px */
@media screen and (max-width: 768px) {
  .legendContainer.compact {
    white-space: normal;
    padding: 5px;
  }
  .legendCompactList {
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    overflow-x: visible;
  }
  .legendCompactItem {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 3px;
  }
  .legendIconCompact {
    width: 17px;
    height: 17px;
    margin-right: 3px;
    margin-bottom: 0;
  }
  .legendCountCompact {
    font-size: 0.4rem;
    margin-left: 2px;
  }
  .legendTitle {
    font-size: 0.55rem;
  }
  .resetButton {
    padding: 3px 5px;
    font-size: 0.45rem;
  }
}
