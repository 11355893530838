/* src/pages/LogsUsuarios/LogsUsuarios.module.css */

/* Container principal */
.administradorContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  /* Controles superiores (pesquisa) */
  .topControls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .searchContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Se desejar limitar a largura para combinar com a página Administrador, pode definir width: 20%; ou ajustar conforme necessário */
    width: 20%;
  }
  
  .searchIcon {
    font-size: 1.2rem;
    color: #555;
  }
  
  .searchInput {
    flex: 1;
    padding: 8px 8px 8px 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 250px;
    font-size: 16px;
  }
  
  .searchButton {
    padding: 8px 16px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .searchButton:hover {
    background-color: #2980b9;
  }
  
  /* Container da tabela */
  .tableContainer {
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  .userTable {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
  }
  
  .userTable th,
  .userTable td {
    border: 1px solid #ddd;
    padding: 12px 8px;
    text-align: center;
  }
  
  .userTable th {
    background-color: #f2f2f2;
    cursor: pointer;
    position: relative;
  }
  
  .userTable th.sortable:hover {
    background-color: #e0e0e0;
  }
  
  .userTable tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .userTable tr:hover {
    background-color: #ddd;
  }
  
  /* Mensagem para quando não houver dados */
  .noData {
    text-align: center;
    padding: 20px;
    color: #555;
    font-style: italic;
  }
  
  /* Botão de ação (ícone de olho) */
  .actionButton {
    background: none;
    border: none;
    cursor: pointer;
    color: #3498db;
    font-size: 1.1rem;
    transition: color 0.2s;
  }
  
  .actionButton:hover:not(:disabled) {
    color: #2980b9;
  }
  
  /* Estilo para botão desabilitado */
  .actionButton:disabled {
    cursor: not-allowed;
    color: #ccc;
  }
  
  /* Paginação */
  .paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .pagination li {
    margin: 0 4px;
  }
  
  .pagination a {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-decoration: none;
    color: #000;
    transition: background-color 0.3s ease;
  }
  
  .pagination a:hover {
    background-color: #f2f2f2;
  }
  
  .paginationActive a {
    background-color: #3498db;
    color: #fff;
    border: 1px solid #3498db;
  }
  
  .paginationDisabled a {
    color: #aaa;
    cursor: not-allowed;
  }
  