/* src/components/AssociacoesWindow.module.css */
.window {
  position: absolute;
  top: 40px;
  left: 20px;
  width: 340px;
  max-height: 80vh;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  overflow-y: auto;
  z-index: 1000;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.closeButton {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
