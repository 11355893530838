.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 30px;
  width: 600px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Modal acima do overlay */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


.closeModalButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.alertaPage {
  display: flex;
  flex-direction: column;
  position: relative; /* Necessário para posicionar o loading overlay */
}

.header {
  margin-bottom: 25px;
  text-align: center;
  font-size: 1.5rem;
}

.fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.formGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.colSpan1 {
  flex: 1 1 calc(50% - 15px);
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .colSpan1 {
    flex: 1 1 100%;
  }
}

.label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.reactSelect {
  margin-top: 5px;
}

.errorMessage {
  color: red;
  font-size: 0.9rem;
}

.button {
  margin-top: 30px;
  padding: 12px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #218838;
}

.loadingOverlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
}


/* Estilos adicionais para o react-select */
.react-select__control {
  border-color: #ccc;
  border-radius: 4px;
}

.react-select__control--is-focused {
  border-color: #007bff;
  box-shadow: 0 0 0 1px #007bff;
}

.react-select__menu {
  z-index: 9999;
}
