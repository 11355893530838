/* Modal.module.css */

/* Sobreposição do Modal com Efeito de Desfoque */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); /* Aplica o desfoque */
  background: rgba(0, 0, 0, 0.4); /* Mantém um leve escurecimento */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
  z-index: 1000;
}

/* Container do Modal */
.modalContainer {
  background: #ffffff;
  border-radius: 12px;
  padding: 25px 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-30px);
  animation: slideDown 0.3s ease-in-out;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Conteúdo do Modal */
.modalContent {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* Botão de Fechamento */
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #555555;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.closeButton:hover {
  color: #000000;
}

/* Título do Modal */
.modalContent h3 {
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: #333333;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ícone de Cidade */
.cityIcon {
  margin-right: 10px;
  font-size: 1.5rem;
  color: #007bff;
}

/* Input do Modal */
.modalContent input {
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
  width: 100%;
}

.modalContent input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Botão de Confirmação */
.modalContent button {
  padding: 12px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modalContent button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.modalContent button:active {
  transform: translateY(0);
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}

/* Responsividade */
@media (max-width: 480px) {
  .modalContainer {
    padding: 20px 15px;
    max-width: 95%;
  }

  .modalContent h3 {
    font-size: 1.1rem;
  }

  .modalContent input {
    padding: 10px;
    font-size: 0.95rem;
  }

  .modalContent button {
    padding: 10px;
    font-size: 0.95rem;
  }

  .closeButton {
    font-size: 1.3rem;
    top: 8px;
    right: 8px;
  }

  .cityIcon {
    font-size: 1.3rem;
    margin-right: 8px;
  }
}
