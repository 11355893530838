/* src/components/ComparacaoConsumoModal/ComparacaoConsumoModal.module.css */

.overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    z-index: 1100;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .modalContent {
    min-height: 300px;
  }
  