.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .modal {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    width: 450px;
    max-width: 90%;
    position: relative;
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .closeButton {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    transition: transform 0.2s;
  }
  
  .closeButton:hover {
    transform: scale(1.2);
  }
  
  .infoContainer {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .infoBlock {
    flex: 1;
  }
  
  .buttonGroup {
    display: flex;
    gap: 0.8rem;
    margin-top: 1.5rem;
    justify-content: flex-end;
  }
  
  .confirmButton {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .confirmButton:hover {
    background-color: #45a049;
  }
  
  .cancelButton {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .cancelButton:hover {
    background-color: #e53935;
  }
  
  .manualButton {
    background-color: #2196f3;
    color: white;
    border: none;
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .manualButton:hover {
    background-color: #1e88e5;
  }
  
  .manualAddressContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  
  .manualAddressInput {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .manualAddressInput:focus {
    border-color: #2196f3;
    outline: none;
  }
  
  .errorMessage {
    color: #f44336;
    font-size: 0.9rem;
  }
  