/* src/components/DeviceListModal/DeviceListModal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  z-index: 2000;
  animation: fadeIn 0.3s;
}

.modalContent {
  background-color: #fff;
  border-radius: 8px;
  width: 300px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.deviceList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.deviceItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background-color 0.2s;
}

.deviceItem:hover {
  background-color: #f9f9f9;
}

.deviceInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.deviceName {
  font-weight: 500;
}

.deviceTension {
  font-size: 0.85rem;
  color: #007BFF;
}

.deviceTime {
  font-size: 0.8rem;
  color: #666;
}

.iconButtons {
  display: flex;
  gap: 8px;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #007BFF;
  transition: color 0.2s;
}

.iconButton:hover {
  color: #0056b3;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
