/* src/pages/Relatorios/Relatorios.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 8px;
}

.invalidGeo {
  color: red;
  font-weight: bold;
}

/* Exemplo simples para o container de resumo */
.geoSummary {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f0f0f0; /* cor de fundo sutil */
  border-radius: 4px;
}

/* Para destacar N/A em laranja */
.naStyle {
  color: orange;
  font-weight: bold;
}

.controlsTop {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.filterGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}

.controlGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  min-width: 180px;
}

.controlGroup label {
  font-weight: 600;
  color: #4CAF50;
  margin-bottom: 4px;
  font-size: 0.9rem;
}

.dropdown {
  padding: 6px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.dropdown:focus {
  border-color: #4CAF50;
  outline: none;
}

.selectInfo {
  font-size: 0.75rem;
  color: #555;
  margin-top: 4px;
}

.actionsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.exportButton {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 0.95rem;
  transition: background-color 0.3s ease;
  width: auto;
  max-width: 200px;
}

.exportButton:hover {
  background-color: #45a049;
}

.clearFiltersButton {
  background: none;
  border: none;
  color: #e74c3c;
  font-size: 0.95rem;
  cursor: pointer;
  text-decoration: underline;
  padding: 5px;
  transition: color 0.3s ease;
  width: auto;
}

.clearFiltersButton:hover {
  color: #c0392b;
}

.tableContainer {
  margin-top: 20px;
  width: 100%;
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  table-layout: auto;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.table th {
  background-color: #f4f4f4;
  font-weight: bold;
  text-align: center;
  font-size: 0.85rem;
}

.infoMessage,
.errorMessage {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 20px;
  color: #888;
}

.errorMessage {
  color: #e74c3c;
}

@media (max-width: 800px) {
  .controlGroup {
    width: 100%;
    max-width: none;
  }
}

/* ===== NOVA CLASSE COM ESPAÇO EXTRA ===== */
.extraSpace {
  margin-right: 40px;
}