/* MarkerModal.module.css */

/* Overlay do Modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}



/* Wrapper do Conteúdo */
.modalContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: filter 0.3s ease;
}

/* Container do Modal */
.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 900px;
  height: 600px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; /* Evita que o conteúdo ultrapasse as bordas */
}

/* Área de Conteúdo do Modal */
.modalContentArea {
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
}

/* Botão de Fechar */
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

/* Botão para Expandir/Ocultar Informações Adicionais */
.expandButton {
  margin-top: 15px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.expandButton:hover {
  background-color: #0056b3;
}

/* Informações Adicionais */
.additionalInfo {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}


/* Título do Modal */
.title {
  font-size: 1.2rem;
  text-align: center;
}

/* Estilos das Abas (React Tabs) */
.react-tabs__tab-list {
  display: flex;
  border-bottom: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
}

.react-tabs__tab {
  padding: 10px 15px;
  cursor: pointer;
  background-color: #f1f1f1;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s ease;
}

.react-tabs__tab--selected {
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom: none;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
}

/* Painéis das Abas */
.react-tabs__tab-panel {
  display: none;
  padding-top: 20px;
}

.react-tabs__tab-panel--selected {
  display: block;
}

/* Layout das Informações Gerais */
.generalInfoContainer {
  display: flex;
  justify-content: space-between;
}

.leftSection,
.rightSection {
  flex: 1;
}

.leftSection p,
.deviceSection p {
  margin: 10px 0;
}

/* Imagem do Poste */
.imageContainer {
  text-align: center;
}

.posteImage {
  max-width: 100%;
  max-height: 410px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Overlay da Imagem em Tamanho Real */
.fullImageOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.fullsizeImage {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Responsividade */
@media screen and (max-width: 768px) {
  .generalInfoContainer {
    flex-direction: column;
  }

  .rightSection {
    margin-top: 20px;
    padding-left: 0;
  }

  .react-tabs__tab-list {
    flex-wrap: wrap;
  }

  .react-tabs__tab {
    flex: 1 1 auto;
    text-align: center;
  }

  .modal {
    width: 95%;
    height: 90%;
  }
}

/* Ícones dos Dispositivos */
.deviceIcons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.activeDeviceIconOn {
  color: #FFD700; /* Amarelo para aceso */
  font-size: 24px;
  margin: 0 5px;
}

.activeDeviceIconOff {
  color: #1E90FF; /* Azul para apagado */
  font-size: 24px;
  margin: 0 5px;
}

.inactiveDeviceIcon {
  color: gray;
  font-size: 24px;
  margin: 0 5px;
}

/* Seção de Alertas */
.alertSection {
  margin-top: 10px;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.alertHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.alertHeader:hover {
  background-color: #eaeaea;
}

.alertDetails {
  margin-top: 5px;
  padding-left: 10px;
}

.resolvedAlert {
  border-left: 5px solid green;
}

.unresolvedAlert {
  border-left: 5px solid orange;
}

.resolvedIcon,
.unresolvedIcon {
  margin-right: 5px;
}

.resolvedIcon {
  color: green;
}

.unresolvedIcon {
  color: orange;
}

.chevronIcon {
  margin-left: auto;
  font-size: 1.2rem;
  color: #666;
}

.alertaTensao {
  color: red;
  font-weight: bold;
}

/* Rodapé do Modal */
.modalFooter {
  text-align: center;
  margin-top: 20px;
}

.updateButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
}

.updateButton:hover {
  background-color: #0056b3;
}

/* Filtros */
.filterToggleContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.filterToggleButton {
  padding: 8px 12px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.filterToggleButton:hover {
  background-color: #5a6268;
}

.filterContainer {
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.filterTitle {
  margin-bottom: 10px;
  font-weight: bold;
}

.filterGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
}

.filterItem {
  flex: 1 1 100px;
  display: flex;
  flex-direction: column;
}

.filterLabel {
  margin-bottom: 5px;
  font-weight: 500;
}

.dropdown {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Última Medição */
.lastMeasurement {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #e8f4fd;
  border: 1px solid #b3d7ff;
  border-radius: 5px;
}

.lastMeasurement h4 {
  margin-bottom: 5px;
}

.lastMeasurement p {
  margin: 0;
}

/* Responsividade para Última Medição */
@media screen and (max-width: 768px) {
  .lastMeasurement {
    margin-bottom: 15px;
  }
}

/* Ações dos Filtros */
.actionsContainer {
  display: flex;
  gap: 10px;
}

.button,
.clearFiltersButton {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.button {
  background-color: #4CAF50;
  color: white;
}

.button:hover {
  background-color: #45a049;
}

.clearFiltersButton {
  background-color: #f44336;
  color: white;
}

.clearFiltersButton:hover {
  background-color: #da190b;
}

/* Seleção de Dispositivo e Métrica */
.deviceSelection,
.metricSelection {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.deviceSelection label,
.metricSelection label {
  margin-bottom: 5px;
  font-weight: bold;
}

.deviceSelection select,
.metricSelection select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Gráfico */
.graphContainer {
  margin-top: 20px;
}
