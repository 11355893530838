.mapaAvancadoPage {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Evita que elementos excedam a área do mapa */
  background-color: #f9f9f9; /* Fundo padrão para carregamento */
}

/* Controles principais */
.controlButtons {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  z-index: 1000; /* Mantém acima do mapa */
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  gap: 10px;
}

/* Botão de localização */
.locationButton {
  position: absolute;
  top: 80px;
  left: 20px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.locationButton:hover {
  background-color: rgba(240, 240, 240, 1);
}

/* Botão de alternância (toggle) para ativos/inativos */
.toggleAtivosButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.toggleAtivosButton:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Efeito de aumento ao passar o mouse */
}

.toggleAtivosButton:active {
  transform: scale(0.95); /* Efeito de clique */
}

/* Dropdown para seleção */
.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dropdownLabel {
  font-size: 0.9rem;
  font-weight: bold;
  color: #555;
}

.dropdownSelect {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 1rem;
  cursor: pointer;
  width: 180px;
  transition: border-color 0.3s ease;
}

.dropdownSelect:focus {
  outline: none;
  border-color: #007bff;
}

/* Barra de carregamento */
.loadingBarContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #e0e0e0;
  z-index: 1200;
}

.loadingBar {
  height: 100%;
  background-color: #007bff;
  width: 0%;
  transition: width 1s linear;
}

/* Responsividade */
@media (max-width: 768px) {
  .controlButtons {
    flex-direction: column;
    align-items: stretch;
    padding: 8px;
  }

  .dropdownContainer {
    width: 100%;
    margin-bottom: 10px;
  }

  .dropdownSelect {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .controlButtons {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    gap: 5px;
  }

  .dropdownSelect {
    font-size: 0.9rem;
    padding: 6px 10px;
    width: 140px;
  }

  .toggleAtivosButton {
    width: 100%;
    text-align: center;
  }
}

/* Mensagem de erro */
.error {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 0, 0, 0.1);
  color: #d9534f;
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 0.9rem;
  z-index: 1100;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease;
}

/* Animação de erro */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Outros ajustes */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

button {
  font-family: inherit;
}
