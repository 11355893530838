/* src/components/ChartCard/ChartCard.module.css */

.card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px; /* Reduzido para 16px */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 100%; /* Controlado pelo Grid no Dashboard */
  box-sizing: border-box;
  margin-bottom: 20px;
  transition: padding 0.3s ease, height 0.3s ease;
}

.compact {
  padding: 8px; /* Ainda menor no modo compacto */
}

.title {
  font-size: 1.0rem; /* Reduzido para 1rem */
  margin-bottom: 12px;
  text-align: center;
  color: #2c3e50;
}

.compact .title {
  font-size: 0.9rem; /* Ainda menor no modo compacto */
  margin-bottom: 8px;
}

.legend {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; /* Reduzido de 15px para 10px */
  margin-top: 12px; /* Reduzido de 15px para 12px */
}

.legendItem {
  display: flex;
  align-items: center;
  font-size: 0.8rem; /* Reduzido de 0.9rem para 0.8rem */
  color: #34495e;
}

.legendColor {
  display: inline-block;
  width: 10px; /* Reduzido de 12px para 10px */
  height: 10px; /* Reduzido de 12px para 10px */
  margin-right: 6px; /* Reduzido de 8px para 6px */
  border-radius: 2px;
}

.loadingBarContainer {
  position: fixed; /* Fixa a barra no viewport */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px; /* Reduzido de 8px para 6px */
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Garante que a barra fique acima de outros elementos */
}

.loadingBar {
  height: 100%;
  background-color: #3498db; /* Cor da barra */
  transition: width 1s linear;
}

.loadingText {
  position: fixed; /* Fixa o texto no viewport */
  bottom: 14px; /* Ajustado para ficar acima da nova altura da barra */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6); /* Fundo semi-transparente */
  color: #fff;
  padding: 2px 6px; /* Reduzido de 2px 8px para 2px 6px */
  border-radius: 4px;
  font-size: 10px; /* Reduzido de 12px para 10px */
  z-index: 1001; /* Acima da barra de carregamento */
}

.compact .legend {
  display: none; /* Remove a legenda no modo compacto para economizar espaço */
}

.compact .chart {
  height: 220px; /* Reduzido de 250px para 220px */
}
