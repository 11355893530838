/* src/components/DeviceDetailsModal/DeviceDetailsModal.module.css */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .modalContent {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
  }
  
  .title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.8rem;
    text-align: center;
  }
  
  .poste {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1rem;
  }
  
  .generalInfoContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .leftSection {
    flex: 2;
  }
  
  .rightSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .detailItem {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .icon {
    color: #007BFF;
    font-size: 1.2rem;
  }
  
  .imageContainer {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .deviceImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  
  /* Aba de Métricas e Gráficos */
  .metricsTab {
    padding: 10px 0;
  }
  
  .filterToggleButton {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .filterToggleButton:hover {
    background-color: #0056b3;
  }
  
  .filtersContainer {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .filterItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .filterItem label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input, .select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .actionsContainer {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .updateButton, .clearFiltersButton {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .clearFiltersButton {
    background-color: #dc3545;
  }
  
  .updateButton:hover {
    background-color: #218838;
  }
  
  .clearFiltersButton:hover {
    background-color: #c82333;
  }
  
  .metricSelection {
    margin-bottom: 20px;
  }
  
  .metricSelection label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .graphContainer {
    width: 100%;
    height: 400px;
  }
  
  .lastMeasurement {
    margin-top: 20px;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  
  .error {
    color: red;
    text-align: center;
  }
  