/* src/components/DeviceModal/DeviceModal.module.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  position: relative;
  box-sizing: border-box; /* Garante que o padding seja considerado no tamanho total */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.content {
  margin-top: 20px;
}

.content h2 {
  margin-bottom: 15px;
  text-align: center;
}

.formGroup {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.formGroup label {
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input,
.formGroup select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Garante que o input ocupe toda a largura disponível */
  box-sizing: border-box; /* Garante que o padding não quebre o layout */
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.submitButton {
  background-color: #4CAF50;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #45a049;
}

.cancelButton {
  background-color: #f44336;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton:hover {
  background-color: #d32f2f;
}
