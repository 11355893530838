.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: #1b2a38;
  color: #ecf0f1;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75); /* Camada escura transparente */
}

.confirmButton {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #d9534f; /* Botão vermelho */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirmButton:hover {
  background-color: #c9302c;
}

.closeModalButton {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff; /* Botão azul */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.closeModalButton:hover {
  background-color: #0056b3;
}
