/* src/components/MarkerListModal/MarkerListModal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .modalContent {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
  }
  
  .markerList {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .markerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    border-bottom: 1px solid #ddd;
  }
  
  .markerInfo {
    font-size: 1rem;
    color: #333;
  }
  
  .focusButton {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .focusButton:hover {
    background-color: #218838;
  }
  