/* src/pages/Bracos/Bracos.module.css */

.header {
    background-color: #f5f6fa;
    padding: 10px;
    text-align: center;
}

.pageTitle {
    font-size: 1.45rem;
    /* Tamanho da fonte para desktop */
    font-weight: normal;
    color: #7f8c8d;
    margin: 0;
    padding: 10px 0;
}


/* Media Queries para dispositivos tablets e móveis */
@media (max-width: 768px) {
    .header {
        padding: 8px;
    }

    .pageTitle {
        font-size: 1.40rem;
        /* Redução do tamanho da fonte para tablets e celulares */
        padding: 8px 0;
    }
}

/* Media Queries para dispositivos móveis menores */
@media (max-width: 480px) {
    .header {
        padding: 6px;
    }

    .pageTitle {
        font-size: 1.10rem;
        /* Redução adicional do tamanho da fonte para celulares */
        padding: 6px 0;
    }
}