/* src/components/PosteGrupos/JanelaGrupos.module.css */

.janelaGrupos {
  position: absolute;
  top: 15%;
  right: 20px;
  width: 250px;
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.janelaGrupos h3 {
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  color: #333;
}

.dropdown {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
}

.actions {
  display: flex;
  gap: 10px;
}

.createButton,
.editButton {
  flex: 1;
  padding: 8px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.createButton {
  background-color: #4caf50;
  color: white;
}

.createButton:hover {
  background-color: #45a049;
}

.editButton {
  background-color: #2196f3;
  color: white;
}

.editButton:hover {
  background-color: #1e88e5;
}

.editButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.createButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}
