.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    max-height: 90vh; /* Define a altura máxima como 90% da altura da tela */
    overflow-y: auto; /* Permite rolagem vertical caso o conteúdo ultrapasse a altura máxima */
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    outline: none;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .closeModalButton {
    background: none;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .modalContent {
    padding: 20px;
  }
  
  .modalTitle {
    margin-bottom: 20px;
  }
  
  .controlGroup {
    margin-bottom: 20px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .slider {
    width: 100%;
  }
  
  .saveButton {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .saveButton:hover {
    background-color: #45a049;
  }
  
  .energySavings {
    margin-top: 20px;
  }
  
  /* ConfigurationProfileModal.module.css */

.hypotheticalInputs {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.hint {
  display: block;
  font-size: 0.9em;
  color: #777;
  margin-top: 4px;
}
