/* Layout Principal */
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.controlsTop {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center; /* Melhor alinhamento dos elementos */
}

/* Botões Principais */
.addButton,
.exportButton {
  background: linear-gradient(145deg, #4caf50, #43a047);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease, transform 0.2s ease;
}

.addButton svg,
.exportButton svg {
  margin-right: 6px;
  font-size: 18px;
}

.addButton:hover,
.exportButton:hover {
  background: linear-gradient(145deg, #45a049, #388e3c);
  transform: translateY(-2px);
}

.addButton:active,
.exportButton:active {
  transform: translateY(0);
}

/* Campos de Entrada */
.searchInput,
.selectInput {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
  min-width: 150px;
}

/* Tabela */
.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 12px 8px;
  text-align: center;
}

.table th {
  background-color: #f2f2f2;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.table th:hover {
  background-color: #e0e0e0;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #ddd;
}

/* Paginação */
.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.paginationActive a {
  background-color: #4caf50;
  color: white;
}

.paginationDisabled a {
  color: #ccc;
  cursor: not-allowed;
}

/* Mensagem de Dados Vazios */
.noData {
  text-align: center;
  padding: 20px;
  font-style: italic;
}

/* Botões de Ação */
.actionButtons {
  display: flex;
  flex-direction: row; /* Alinha os botões horizontalmente */
  align-items: center; /* Centraliza os botões verticalmente */
  gap: 8px;            /* Espaçamento entre os botões */
}

/* Botão para Cadastrar Ordem de Serviço */
.actionButton {
  background: linear-gradient(145deg, #4caf50, #43a047);
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease, transform 0.2s ease;
}

.actionButton svg {
  margin-right: 4px;
}

.actionButton:hover {
  background: linear-gradient(145deg, #45a049, #388e3c);
  transform: translateY(-1px);
}

.actionButton:active {
  transform: translateY(0);
}

.actionButton:disabled {
  background: #a5d6a7;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Botão "Cancelar" */
.cancelButton {
  background: linear-gradient(145deg, #f44336, #e53935);
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease, transform 0.2s ease;
}

.cancelButton svg {
  margin-right: 4px;
}

.cancelButton:hover {
  background: linear-gradient(145deg, #e53935, #d32f2f);
  transform: translateY(-1px);
}

.cancelButton:active {
  transform: translateY(0);
}

.cancelButton:disabled {
  background: #ef9a9a;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Tooltip para Botões Desativados (opcional) */
.disabledTooltip {
  margin-left: 8px;
  color: #000;
  font-size: 0.8em;
  white-space: nowrap;
}

/* Responsividade */
@media (max-width: 768px) {
  .controlsTop,
  .controls {
    flex-direction: column;
    align-items: stretch;
  }

  .searchInput,
  .selectInput {
    flex: 1 1 100%;
  }

  .table {
    min-width: 600px;
  }

  .actionButtons {
    flex-direction: column; /* Estica os botões na vertical */
    align-items: stretch;
  }

  .actionButton,
  .cancelButton {
    width: 100%; /* Cada botão ocupa a largura total */
  }
}
