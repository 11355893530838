/* src/components/PerfilModal/PerfilModal.module.css */

.modal {
    background: white;
    padding: 20px;
    max-width: 900px;
    margin: auto;
    border-radius: 8px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formGroup input,
  .formGroup select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .formGroupCheckbox {
    margin-bottom: 15px;
  }
  
  .formGroupCheckbox label {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  
  .formGroupCheckbox input {
    margin-right: 10px;
  }
  
  .permissoesTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .permissoesTable th,
  .permissoesTable td {
    border: 1px solid #dee2e6;
    padding: 8px 12px;
    text-align: center;
  }
  
  .permissoesTable th {
    background-color: #f8f9fa;
  }
  
  .formActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .cancelButton {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .cancelButton:hover {
    background-color: #c82333;
  }
  
  .submitButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #0069d9;
  }
  