/* src/pages/PosteGrupos/PosteGrupos.module.css */

.posteGruposPage {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Garante que nenhum conteúdo extrapole o container */
}

/* Ajustes para a barra de ferramentas à esquerda */
.ferramentasBarra {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 10; /* Garante que fique acima do mapa */
  background-color: rgba(255, 255, 255, 0.9); /* Fundo semi-transparente */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

/* Ajustes para a janela de grupos à direita */
.janelaGrupos {
  position: absolute;
  top: 80px; /* Ajuste conforme necessário */
  right: 20px;
  z-index: 10; /* Garante que fique acima do mapa */
  background-color: rgba(255, 255, 255, 0.95); /* Fundo quase opaco */
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  max-height: 70vh; /* Limita a altura máxima */
  overflow-y: auto; /* Adiciona rolagem vertical se necessário */
}

/* Estilo para a lista de grupos */
.janelaGrupos ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.janelaGrupos li {
  padding: 8px 12px;
  margin-bottom: 5px;
  background-color: #f8f9fa;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.janelaGrupos li:hover {
  background-color: #e2e6ea;
}

/* Estilo para o banner de edição no topo */
.editingBanner {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0f0f0;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 15; /* Acima do mapa e das janelas */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Botão para sair do modo de edição */
.stopEditingButton {
  background-color: #d9534f;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.stopEditingButton:hover {
  background-color: #c9302c;
}

/* Botão central para "Adicionar / Remover Postes" */
.editGroupButton {
  position: absolute;
  top: 20px; /* Ajuste conforme necessário */
  left: 50%;
  transform: translateX(-50%);
  z-index: 15; /* Garante que fique acima do mapa */
}

.editGroupButton button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s, transform 0.2s;
}

.editGroupButton button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Mensagem para Grupos Vazios */
.noGroups {
  position: absolute;
  top: 120px; /* Posiciona abaixo dos botões de edição */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.95);
  color: #555;
  padding: 12px 20px;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  text-align: center;
}

/* Estilo para o mapa */
.googleMap {
  width: 100%;
  height: 100vh;
}

/* Estilo para o banner de erro */
.error {
  position: absolute;
  top: 50px; /* Ajuste conforme necessário */
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 20; /* Acima de todos os elementos */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: bold;
}

/* Estilo para o botão de edição e outros elementos interativos */
.button {
  cursor: pointer;
}

/* Responsividade */
@media (max-width: 768px) {
  .ferramentasBarra {
    left: 5px;
    padding: 8px;
  }

  .janelaGrupos {
    top: 70px;
    right: 10px;
    padding: 10px;
    max-height: 60vh;
  }

  .editingBanner {
    top: 5px;
    padding: 8px 15px;
    font-size: 12px;
  }

  .editGroupButton {
    top: 15px;
  }

  .editGroupButton button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .noGroups {
    top: 100px;
    padding: 10px 15px;
    font-size: 13px;
  }

  .error {
    top: 40px;
    padding: 8px 15px;
    font-size: 13px;
  }
}
