/* src/pages/Administrador/Administrador.module.css */

.administradorContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    /* Adiciona espaçamento interno */
}

/* Controles superiores: Pesquisa */
.topControls {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

/* Pesquisa */
.searchContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 300px;
}

.searchIcon {
    position: absolute;
    left: 10px;
    color: #7f8c8d;
}

.searchInput {
    width: 100%;
    padding: 8px 8px 8px 35px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
}

.searchInput:focus {
    outline: none;
    border-color: #4CAF50;
}

/* Tabela de Usuários */
.tableContainer {
    overflow-x: auto;
}

.userTable {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
    background-color: #fff;
    /* Fundo branco */
}

.userTable th,
.userTable td {
    border: 1px solid #ddd;
    padding: 12px 8px;
    text-align: center;
    vertical-align: middle;
    /* Alinha verticalmente ao meio */
}

.userTable th {
    background-color: #f2f2f2;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    position: relative;
}

.userTable th:last-child {
    cursor: default;
    /* Cursor padrão para "Ações" */
}

.userTable th svg {
    margin-left: 5px;
    font-size: 0.8rem;
}

.userTable th:last-child svg {
    display: none;
    /* Remover ícones de ordenação para "Ações" */
}

.userTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

.userTable tr:hover {
    background-color: #f1f1f1;
}

.noData {
    text-align: center;
    padding: 20px;
    color: #7f8c8d;
    font-style: italic;
}

/* Botões de Ação */
.actionButton {
    background-color: #4CAF50;
    /* Verde */
    color: white;
    padding: 8px;
    margin: 0 2px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.actionButton:hover {
    background-color: #45a049;
    transform: scale(1.1);
}

/* Estilos para o botão de toggle ativo */
.toggleActive {
    background-color: #27ae60;
    /* Verde para ativo */
}

.toggleActive:hover {
    background-color: #1e8449;
}

/* Estilos para o botão de toggle inativo */
.toggleInactive {
    background-color: #e74c3c;
    /* Vermelho para inativo */
}

.toggleInactive:hover {
    background-color: #c0392b;
}

.toggleButton:hover {
    background-color: #e08e0b;
}

/* Nova Classe para a Coluna de Ícone */
.iconCell {
    position: relative;
}

/* Tooltip */
.tooltipContainer {
    position: relative;
    display: inline-block;
}

.tooltipContainer .tooltipText {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Posiciona o tooltip acima do ícone */
    left: 50%;
    margin-left: -80px;
    /* Centraliza horizontalmente */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.9rem;
}

.tooltipContainer .tooltipText::after {
    content: "";
    position: absolute;
    top: 100%;
    /* Fica na parte inferior do tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltipContainer:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

/* Responsividade */
@media (max-width: 768px) {
    .topControls {
        flex-direction: column;
        align-items: stretch;
    }

    .userTable {
        min-width: 600px;
    }
}

@media (max-width: 480px) {
    .searchContainer {
        max-width: 100%;
    }

    .userTable {
        min-width: 500px;
    }

    .actionButton {
        padding: 6px;
        font-size: 0.9rem;
    }

    .tooltipContainer .tooltipText {
        width: 120px;
        margin-left: -60px;
    }
}

/* Estilo para mensagens de erro */
.error {
    color: #e74c3c;
    font-weight: bold;
    text-align: center;
}