/* src/pages/Mapa/MoverPontos.module.css */

.moverPontosPage {
  position: relative;
  width: 100%;
  height: 100vh;
}

.errorMessage {
  color: red;
  font-weight: bold;
  padding: 1rem;
}

/* Estilos para o card de informações do poste */
.posteInfoCard {
  position: absolute;
  top: 20px; /* Ajuste para não sobrepor a Toolbar */
  left: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  z-index: 20; /* Garante que fique acima dos botões e do mapa */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Foto do Poste */
.postePhoto {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Placeholder para a foto do poste */
.photoPlaceholder {
  width: 100%;
  height: 180px;
  background-color: #d3d3d3; /* Cinza claro */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #555;
  font-size: 1em;
  margin-bottom: 10px;
}

/* Título do card */
.posteInfoCard h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
}

/* Parágrafos do card */
.posteInfoCard p {
  margin: 5px 0;
  font-size: 1em;
  color: #555;
}

/* Container para o novo endereço */
.newAddressContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Container para input e botão de pesquisa alinhados */
.searchInputContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Estilo para o input de "Novo Endereço" */
.newAddressInput {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

/* Estilo para o botão de Pesquisar */
.searchButton {
  background-color: #17a2b8; /* Azul claro */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, transform 0.2s;
}

.searchButton:hover {
  background-color: #117a8b; /* Azul mais escuro ao passar o mouse */
  transform: scale(1.05);
}

/* Estilos para a lista de resultados de pesquisa */
.searchResults {
  margin-top: 10px;
}

.searchResults h4 {
  margin-bottom: 5px;
  font-size: 1em;
  color: #333;
}

.searchResults ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.searchResultItem {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.searchResultItem:hover {
  background-color: #f1f1f1;
}

/* Grupo de botões dentro do card */
.buttonGroup {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

/* Botão "Mover Posição do Marcador" */
.movePositionButton {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s, transform 0.2s;
}

.movePositionButton:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Botão "Aplicar" */
.applyPositionButton {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s, transform 0.2s;
}

.applyPositionButton:hover {
  background-color: #1e7e34;
  transform: scale(1.05);
}

/* Botão "Cancelar" */
.cancelPositionButton {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s, transform 0.2s;
}

.cancelPositionButton:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

.addressError {
  color: red;
  font-weight: bold;
}

/* Botão "Limpar Filtro" dentro do painel de configurações */
.clearButton {
  background-color: #ffc107;
  color: #000;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

/* Novos estilos para o botão de configurações (engrenagem) */
.settingsButton {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 30;
}

/* Painel de configurações para intervalo de postes */
.settingsPanel {
  position: absolute;
  bottom: 60px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  z-index: 30;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.settingsContent h4 {
  margin: 0 0 10px 0;
}

.inputGroup {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.inputGroup label {
  margin-bottom: 5px;
  font-size: 0.9em;
}

.inputGroup input {
  padding: 5px;
  font-size: 0.9em;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 25%;
}

.buttonGroup {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.applyButton {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

/* Estilos para a barra de carregamento */
.loadingBarContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
  z-index: 40;
}

.loadingBar {
  height: 100%;
  background-color: #007bff;
  transition: width 0.2s ease;
}

/* Responsividade */
@media (max-width: 768px) {
  .posteInfoCard {
    top: 60px;
    left: 10px;
    padding: 10px;
    max-width: 250px;
  }

  .posteInfoCard h3 {
    font-size: 1em;
  }

  .posteInfoCard p {
    font-size: 0.9em;
  }

  .newAddressInput {
    font-size: 12px;
  }

  .searchButton {
    padding: 6px;
    font-size: 16px;
  }

  .searchResults h4 {
    font-size: 0.9em;
  }

  .searchResultItem {
    padding: 6px;
    font-size: 0.9em;
  }

  .buttonGroup {
    flex-direction: column;
    gap: 5px;
  }

  .movePositionButton,
  .applyPositionButton,
  .cancelPositionButton {
    padding: 6px 10px;
    font-size: 12px;
  }

  .searchInputContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .searchInputContainer input {
    width: 100%;
  }

  .searchButton {
    width: 100%;
    padding: 8px;
  }

  .postePhoto,
  .photoPlaceholder {
    height: 150px;
  }
}
