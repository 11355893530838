/* src/pages/Postes/PosteModal.module.css */

/* Contêiner para cada item de entrada de dados e botões */
.itemContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

/* Estilo menor para input de EUI, para ocupar menos espaço */
.euiInputSmaller {
  width: 100%;
  max-width: 250px;
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Contêiner que agrupa os botões Salvar e Remover */
.buttonContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

/* Estilos para todos os botões (com variáveis para cores específicas) */
.button,
.saveButton,
.removeButton {
  padding: 8px 10px;
  font-size: 0.9rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Estilo específico para o botão de adicionar EUI */
.button {
  background-color: #007bff;
}

.button:hover {
  background-color: #0056b3;
}

/* Botão de salvar */
.saveButton {
  background-color: #28a745;
}

.saveButton:hover {
  background-color: #218838;
}

/* Botão de remover */
.removeButton {
  background-color: #d9534f;
}

.removeButton:hover {
  background-color: #c9302c;
}

/* Estilos para botões desabilitados */
.button:disabled,
.addButton:disabled,
.saveButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Opção opcional: estilizar os botões desabilitados */
.button:disabled:hover,
.addButton:disabled:hover,
.saveButton:disabled:hover {
  background-color: #ccc;
}

/* Estilo para o contêiner do input EUI */
.euiInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Estilos para os labels */
.label {
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 0.9rem;
  color: #555;
  display: flex;
  align-items: center;
}

.label svg,
.label img {
  margin-right: 5px;
  font-size: 1rem;
  width: 20px;
  height: 20px;
  color: #007bff;
}

/* Estilos para os fieldsets */
.fieldset {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Estilos para os grupos de formulário */
.formGroup {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.formGroup > div {
  display: flex;
  flex-direction: column;
}

/* Estilos para os inputs */
.input,
.euiInput {
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

/* Estilos para o campo com botão de formatação */
.inputWithButton {
  display: flex;
  align-items: center;
}

.formatButton {
  margin-left: 10px;
  padding: 8px 12px;
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formatButton:hover {
  text-decoration: underline;
}

/* Estilos para estados de erro */
.inputError {
  border-color: #d9534f;
}

.errorMessage {
  color: #d9534f;
  font-size: 0.8rem;
  margin-top: 5px;
}

.required {
  color: #d9534f;
  margin-left: 2px;
}

/* Estilos para a página principal do poste dentro do modal */
.postePage {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Tamanho fixo para o modal com responsividade */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 90%;
  max-width: 1200px;
  height: 90%;
  max-height: 90vh;
  overflow: hidden;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  outline: none;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Estilos para o header */
.header {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos para o botão de submissão */
.submitButton {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #218838;
}

/* Estilos para mensagens informativas */
.infoMessage {
  color: #555;
  font-size: 14px;
  margin-top: 5px;
}

/* Estilos para o overlay do modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1001; /* Z-Index acima do Sidebar */
}

/* Botão de fechar o modal */
.closeModalButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  font-size: 1rem;
  color: #fff;
  background-color: #d9534f;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.closeModalButton:hover {
  background-color: #c9302c;
}

/* Estilos para botões de confirmação */
.confirmButton {
  margin-right: 10px;
  padding: 8px 16px;
  font-size: 0.9rem;
  color: #fff;
  background-color: #d9534f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirmButton:hover {
  background-color: #c9302c;
}

/* Estilos específicos para o contêiner da imagem */
.imageContainer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.previewImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.removeImageButton {
  background: none;
  border: none;
  color: #d9534f;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 5px 10px;
  background-color: transparent;
  text-decoration: underline;
}

.removeImageButton:hover {
  color: #c9302c;
}

/* Classes para colunas de grid */
.colSpan1 {
  grid-column: span 1;
}

.colSpan2 {
  grid-column: span 2;
}

.colSpan3 {
  grid-column: span 3;
}

/* Estilos para contêineres de erro e botão de retry */
.errorContainer {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f2dede;
}

.errorMessage {
  color: #a94442;
  margin-bottom: 10px;
}

.retryButton {
  display: flex;
  align-items: center;
  background-color: #f0ad4e;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.retryButton:hover {
  background-color: #ec971f;
}

.geoContainer{
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

/* Animação para destacar o botão de salvar */
@keyframes highlightSaveButton {
  0% {
    transform: scale(1);
    background-color: #ffd900;
  }
  50% {
    transform: scale(1.25);
    background-color: #e9da85;
  }
  100% {
    transform: scale(1);
    background-color: #ffd900;
  }
}

.geoButton {
  background-color: #007bff; /* Azul vibrante */
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  align-self: flex-end;
  margin-left: 8px;
}

.geoButton:hover {
  background-color: #0056b3; /* Azul mais escuro para hover */
}

.geoButton:active {
  transform: scale(0.95);
}

.geoButton svg {
  width: 20px;
  height: 20px;
}


/* Classe para adicionar a animação no botão de salvar */
.animateSaveButton {
  animation: highlightSaveButton 0.5s ease-in-out;
}

/* Responsividade geral */
@media (max-width: 992px) {
  .formGroup {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .itemContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttonContainer {
    width: 100%;
    justify-content: flex-start;
  }

  .button,
  .saveButton,
  .removeButton {
    width: 100%;
    max-width: 200px;
    height: auto;
  }

  .previewImage {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 768px) {
  .formGroup {
    grid-template-columns: 1fr;
  }

  .itemContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttonContainer {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .button,
  .saveButton,
  .removeButton {
    width: 48%;
    max-width: none;
  }

  .previewImage {
    width: 100%;
    height: auto;
  }

  .imageContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .modal {
    width: 95%;
    height: 95%;
    padding: 10px;
  }

  .postePage {
    padding: 10px;
  }

  .header {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .submitButton {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .label {
    font-size: 0.8rem;
  }

  .input,
  .euiInput {
    font-size: 0.8rem;
    padding: 6px;
  }

  .saveButton,
  .removeButton,
  .button {
    font-size: 0.8rem;
    padding: 6px 8px;
  }

  .retryButton {
    padding: 6px 10px;
    font-size: 0.8rem;
  }

  .closeModalButton {
    width: 30px;
    height: 30px;
    font-size: 0.9rem;
  }
}

/* Dropdown container */
.dropdown {
  position: relative;
  width: 100%;
}

/* Lista de dropdown */
.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1000;
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Estilo dos itens da lista do dropdown */
.dropdownItem {
  padding: 8px 10px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}

/* Botão de adicionar */
.addButton {
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addButton:hover {
  background-color: #0056b3;
}

/* Botão de formatação específico para Código Poste */
.formatButton {
  margin-left: 10px;
  padding: 8px 12px;
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formatButton:hover {
  text-decoration: underline;
}

/* Classe para mensagens de sucesso e erro (opcional) */
.successMessage {
  color: #28a745;
  font-size: 0.9rem;
  margin-top: 5px;
}

.errorMessage {
  color: #d9534f;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Classe para o botão de formatação animado */
.animateFormatButton {
  animation: highlightFormatButton 0.5s ease-in-out;
}

@keyframes highlightFormatButton {
  0% {
    transform: scale(1);
    background-color: #ffd900;
  }
  50% {
    transform: scale(1.25);
    background-color: #e9da85;
  }
  100% {
    transform: scale(1);
    background-color: #ffd900;
  }
}
