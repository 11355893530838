/* FiltroGrupos.module.css */

.filtroGrupos {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 280px;
  background-color: rgba(255, 255, 255, 0.98);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

.filtroGrupos.collapsed {
  height: 50px;
  overflow: hidden;
  padding: 10px 20px;
}

.filtroGrupos:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.filtroGrupos h4 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: #2c3e50;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
}

.filtroGrupos h4 .toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #3498db;
  transition: color 0.3s ease;
}

.filtroGrupos h4 .toggleButton:hover {
  color: #2980b9;
}

.grupoList {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.grupoList::-webkit-scrollbar {
  width: 6px;
}

.grupoList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.grupoList::-webkit-scrollbar-thumb {
  background: #bdc3c7;
  border-radius: 3px;
}

.grupoItem {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ecf0f1;
}

.grupoItem:last-child {
  border-bottom: none;
}

.grupoLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.grupoLabel input {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.grupoNome {
  margin-left: 12px;
  font-size: 0.95rem;
  color: #34495e;
  flex-grow: 1;
}

.grupoCor {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-left: 10px;
  border: 1px solid #bdc3c7;
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.clearButton,
.refreshButton {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.clearButton:hover,
.refreshButton:hover {
  background-color: #2980b9;
}

.clearButton:active,
.refreshButton:active {
  background-color: #1c5980;
}

.clearButton svg,
.refreshButton svg {
  margin-right: 6px;
}

.error {
  color: #e74c3c;
  background-color: #fdecea;
  padding: 10px;
  border-radius: 6px;
  font-size: 0.9rem;
  margin-bottom: 15px;
  text-align: center;
}

.loading {
  font-size: 0.9rem;
  color: #7f8c8d;
  text-align: center;
}

.noPostes {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 280px;
  background-color: rgba(255, 255, 255, 0.98);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  text-align: center;
  color: #7f8c8d;
}
