/* src/components/PageLayout/PageLayout.module.css */

.pageLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #f5f6fa;
  padding: 10px;
  text-align: center;
}

.pageTitle {
  font-size: 1.75rem; /* Tamanho da fonte para desktop */
  font-weight: normal;
  color: #7f8c8d;
  margin: 0;
  padding: 10px 0;
}

.pageContent {
  flex: 1;
  padding: 20px;
  background-color: #f5f6fa;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}

.footer {
  background-color: #16222A;
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
  font-size: 0.9em;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

/* Media Queries para dispositivos tablets e móveis */
@media (max-width: 768px) {
  .header {
    padding: 8px;
  }

  .pageTitle {
    font-size: 1.5rem; /* Redução do tamanho da fonte para tablets e celulares */
    padding: 8px 0;
  }

  .pageContent {
    padding: 15px;
    gap: 15px;
  }

  .footer {
    height: 60px;
    font-size: 1em;
  }
}

/* Media Queries para dispositivos móveis menores */
@media (max-width: 480px) {
  .header {
    padding: 6px;
  }

  .pageTitle {
    font-size: 1.25rem; /* Redução adicional do tamanho da fonte para celulares */
    padding: 6px 0;
  }

  .pageContent {
    padding: 10px;
    gap: 10px;
  }

  .footer {
    height: 50px;
    font-size: 0.95em;
  }
}
