/* src/pages/Levantamentos/Levantamentos.module.css */

/* ========================================
   Container Principal da Página
   ======================================== */
   .levantamentosPage {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espaço entre os elementos principais */
    padding: 20px;
    box-sizing: border-box;
    background-color: #f9f9f9; /* Cor de fundo suave para a página */
    border-radius: 8px;
}

/* ========================================
   Seção de Controles (Dropdown Tipo de Dados)
   ======================================== */
.controls {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espaço entre o grupo de controles e outros elementos */
    width: 100%;
    margin-bottom: 20px; /* Espaço adicional abaixo dos controles */
}

/* Grupo de Controle */
.controlGroup {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Espaço entre o label e o select */
}

/* Container para o Dropdown */
.dataTypeContainer {
    display: flex;
    align-items: center;
    /* gap: 10px; */ /* Removido, já que não há mais o botão */
}

/* Labels dos Controles */
.controlGroup label {
    font-weight: 600;
    color: #4CAF50; /* Cor verde para os rótulos */
    margin-bottom: 4px;
    font-size: 0.9rem;
}

/* Select Inputs */
.selectInput {
    padding: 8px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 170px; /* Reduzido de 200px para 150px */
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.selectInput:focus {
    border-color: #4CAF50; /* Cor verde ao focar */
    outline: none;
}

/* ========================================
   Container dos Gráficos
   ======================================== */
.chartContainer {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espaço entre múltiplos gráficos, se houver */
    width: 100%;
}

/* ========================================
   Cartão do Gráfico
   ======================================== */
.chartCard {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espaço entre os elementos dentro do cartão */
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Título do Gráfico */
.chartTitle {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4CAF50; /* Cor verde */
    text-align: center;
}

/* ========================================
   Seção de Filtros nos Componentes de Gráficos
   ======================================== */
.controlsTop {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espaço entre grupos de filtros e botões */
    width: 100%;
}

/* Grupo de Filtros */
.filterGroup {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Espaço entre o título e o container de filtros */
    width: 100%;
}

/* Título do Grupo de Filtros */
.filterGroupTitle {
    font-size: 1rem;
    font-weight: 600;
    color: #4CAF50; /* Cor verde */
    margin-bottom: 12px;
}

/* Container Flexível para Inputs Lado a Lado */
.filtersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Espaçamento entre os filtros */
    width: 100%;
}

/* Cada Filtro */
.filterItem {
    display: flex;
    flex-direction: column;
    width: 200px; /* Define uma largura fixa para os filtros */
    min-width: 150px; /* Largura mínima para manter legibilidade */
}

/* Labels dos Filtros */
.filterLabel {
    font-weight: 600;
    color: #4CAF50; /* Cor verde */
    margin-bottom: 4px;
    font-size: 0.85rem;
}

/* Inputs e Selects nos Filtros */
.dropdown {
    padding: 6px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.dropdown:focus {
    border-color: #4CAF50; /* Cor verde ao focar */
    outline: none;
}

/* ========================================
   Botões de Ação nos Filtros
   ======================================== */
.actionsContainer {
    display: flex;
    gap: 10px; /* Espaçamento entre os botões */
    justify-content: flex-start;
    flex-wrap: wrap;
}

/* Botão Principal */
.button {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.95rem;
    transition: background-color 0.3s ease;
    max-width: 200px;
}

.button:hover {
    background-color: #45a049;
}

/* ========================================
   Mensagens de Status
   ======================================== */
.infoMessage,
.errorMessage {
    text-align: center;
    font-size: 1.1rem;
    margin-top: 20px;
    color: #888;
}

.errorMessage {
    color: #e74c3c;
}

/* ========================================
   Responsividade para Telas Menores
   ======================================== */
@media (max-width: 800px) {
    /* Ajuste nos Filtros dos Componentes de Gráficos */
    .filtersContainer {
        flex-direction: column;
    }

    .filterItem {
        width: 100%;
        max-width: none;
    }

    .actionsContainer {
        flex-direction: column;
        align-items: flex-start;
    }

    .button {
        width: 100%;
        max-width: none;
    }

    /* Ajuste no Container do Dropdown */
    .dataTypeContainer {
        flex-direction: column;
        align-items: flex-start;
    }
}
