.buttonGroup {
    display: flex;
    gap: 0.5rem; /* Espaçamento entre os botões */
  }

  .submitButton {
    padding: 0.4rem 0.6rem; /* Ajuste o padding */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem; /* Ajuste o tamanho da fonte, se necessário */
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .cancelButton {
    padding: 0.4rem 0.6rem; /* Ajuste o padding */
    background-color: #dc3545; /* Cor de fundo (vermelho) */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem; /* Tamanho da fonte */
  }
  
  .cancelButton:hover {
    background-color: #c82333; /* Cor ao passar o mouse */
  }

  .addButton {
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .addButton:hover {
    background-color: #218838;
  }
  