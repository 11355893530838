/* src/pages/Mapa/Mapa.module.css */

/* Variáveis CSS para consistência e facilidade de manutenção */
:root {
  --primary-color: #007bff;
  --secondary-color: #8ec3b9;
  --background-color: #ffffff;
  --overlay-background: rgba(0, 0, 0, 0.6);
  --error-bg: rgba(255, 0, 0, 0.8);
  --text-color: #333333;
  --border-color: #cccccc;
  --hover-bg: #e6f7ff;
}

.infoFloating {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(0, 44, 105, 0.75);
  color: #463b3b;
  padding: 10px 15px;
  border-radius: 4px;
  z-index: 3000;
  color:#f9f9f9
}


.cancelButton {
  background: #e74c3c;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}



/* Layout Geral */
.mapPage {
  position: relative;
  width: 100%;
  height: 100vh;
  font-family: Arial, sans-serif;
}

/* Mensagem de Erro de Localização */
.locationError {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--error-bg);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1500;
  font-weight: bold;
}

.legendList li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  transition: opacity 0.2s;
}

.legendList li:last-child {
  margin-bottom: 0;
}

.legendList li:hover {
  opacity: 0.8;
}

.legendList li.inactive {
  opacity: 0.5;
  text-decoration: line-through;
}

.legendList .icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* Botão de Minimizar/Expandir Legenda */
.minimizeButton {
  width: 100%;
  padding: 6px 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.minimizeButton:hover {
  background-color: #0056b3; /* Substituir darken(var(--primary-color), 10%) por um valor direto */
}

/* Responsividade para Legenda */
@media (max-width: 600px) {
  .legendContainer {
    width: 150px;
    padding: 8px;
    bottom: 15px;
    right: 15px;
  }

  .legendTitle {
    font-size: 1rem;
  }

  .legendList .icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  .minimizeButton {
    font-size: 0.8rem;
    padding: 5px 0;
  }
}

/* Barra de Carregamento de Atualização */
.loadingBarContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: var(--border-color);
  z-index: 1200;
}

.loadingBar {
  height: 100%;
  background-color: var(--primary-color);
  width: 0%;
  transition: width 1s linear;
}

.loadingText {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.85rem;
  z-index: 1300;
}

/* Modal */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.modalContent {
  background-color: var(--background-color);
  border-radius: 8px;
  width: 90%;
  max-width: 700px;
  max-height: 80%;
  overflow-y: auto;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  animation: slideDown 0.3s ease-in-out;
}

@keyframes slideDown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modalTitle {
  font-size: 1.3rem;
  color: var(--text-color);
  margin: 0;
  font-weight: bold;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
  transition: color 0.2s;
}

.closeButton:hover {
  color: var(--primary-color);
}

.modalBody {
  /* Adicione estilos adicionais conforme necessário */
}

/* Tabelas Consolidadas */
.combinedTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.combinedTable th,
.combinedTable td {
  border: 1px solid #dddddd;
  padding: 10px;
  text-align: left;
  font-size: 0.9rem;
}

.combinedTable th {
  background-color: var(--secondary-color);
  color: white;
}

.combinedTable tbody tr {
  background-color: #f9f9f9;
  transition: background-color 0.2s;
}

.combinedTable tbody tr:hover {
  background-color: var(--hover-bg);
}

/* Diferenciar Postes e Dispositivos nas Trs */
.combinedTable tbody tr:not(.deviceRow):hover {
  cursor: pointer;
}

.combinedTable tbody tr.deviceRow:hover {
  cursor: default;
}

/* Lista de Dispositivos Associados */
.deviceList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.deviceItem {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 6px;
  background-color: #fafafa;
  transition: background-color 0.2s;
}

.deviceItem:hover {
  background-color: var(--hover-bg);
}

/* Responsividade para Tabelas */
@media (max-width: 600px) {
  .combinedTable th,
  .combinedTable td {
    padding: 8px;
    font-size: 0.8rem;
  }

  .deviceItem {
    padding: 6px;
    font-size: 0.8rem;
  }
}

/* Barra de Ferramentas */
.searchBarContainer {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 500px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 15px;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  z-index: 1500;
}

.searchInput {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 0.95rem;
  transition: border-color 0.3s;
}

.searchInput:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.searchButton {
  margin-top: 8px;
  width: 100%;
  padding: 8px 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background-color 0.3s;
}

.searchButton:hover {
  background-color: #0056b3; /* Substituir darken(var(--primary-color), 10%) por um valor direto */
}

/* Responsividade para Barra de Ferramentas */
@media (max-width: 600px) {
  .searchBarContainer {
    top: 15px;
    padding: 8px 12px;
  }

  .searchInput {
    font-size: 0.85rem;
    padding: 6px 10px;
  }

  .searchButton {
    font-size: 0.85rem;
    padding: 6px 0;
  }
}

/* Logotipos da Empresa */
.companyLogosContainer {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1500;
}

.companyLogo {
  width: 40px;
  margin-bottom: 8px;
  transition: transform 0.3s, opacity 0.3s;
}

.companyLogo:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

/* Responsividade para Logotipos */
@media (max-width: 600px) {
  .companyLogosContainer {
    top: 15px;
    right: 15px;
  }

  .companyLogo {
    width: 30px;
    margin-bottom: 6px;
  }
}

/* Estilização para o Modal de Associação */
.atrelarModalContent {
  /* Adicione estilos específicos se necessário */
}

/* Outras Classes Específicas */
.deviceRow {
  /* Estilos específicos para as linhas de dispositivos, se necessário */
}

/* Estilização de Clusters (Opcional) */
.marker-cluster {
  background-color: var(--primary-color);
  border-radius: 50%;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}
