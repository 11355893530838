/* src/pages/Auditoria/Auditoria.module.css */

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  .controlsTop {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .addButton {
    background-color: #28a745; /* Verde */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .addButton:hover {
    background-color: #218838;
  }
  
  .controls {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  
  .searchContainer {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .searchInput {
    width: 300px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .tableContainer {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1200px; /* Ajuste conforme necessário */
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 12px 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f8f9fa;
    cursor: pointer;
    position: relative;
  }
  
  .table th:hover {
    background-color: #e2e6ea;
  }
  
  .table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tr:hover {
    background-color: #ddd;
  }
  
  .actionsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px; /* Espaçamento entre os botões */
  }
  
  .actionButton {
    background-color: #17a2b8; /* Azul */
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
  }
  
  .deleteButton {
    background-color: #dc3545; /* Vermelho */
  }
  
  .verifyButton {
    background-color: #ffc107; /* Amarelo */
    color: #212529;
  }
  
  .actionButton:hover,
  .deleteButton:hover,
  .verifyButton:hover {
    opacity: 0.8;
  }
  
  .paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination a {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .pagination a:hover {
    background-color: #f2f2f2;
  }
  
  .paginationActive a {
    background-color: #28a745;
    color: white;
    border: 1px solid #28a745;
  }
  
  .paginationDisabled a {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .noData {
    text-align: center;
    padding: 20px;
    color: #555;
    font-style: italic;
  }
  
  /* Estilos para os Status das Verificações */
  .statusOK {
    color: #28a745; /* Verde */
    font-weight: bold;
  }
  
  .statusNecessitaManutencao {
    color: #ffc107; /* Amarelo */
    font-weight: bold;
  }
  
  .statusNaoAplicavel {
    color: #6c757d; /* Cinza */
    font-weight: bold;
  }
  