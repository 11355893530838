/* 🎨 Variáveis Globais */
:root {
  --primary-color: #007bff;
  --primary-hover: #0056b3;
  --success-color: #28a745;
  --success-hover: #218838;
  --danger-color: #dc3545;
  --danger-hover: #c82333;
  --info-color: #17a2b8;
  --info-hover: #138496;
  --background-light: #f7f9fb;
  --text-dark: #333;
  --text-light: #666;
  --border-color: #ddd;
}

/* 🟢 Modal */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: var(--background-light);
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 🔳 Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/* Botão de Fechar (X) no canto superior direito */
.closeModalButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #666;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 10;
}

.closeModalButton:hover {
  color: #000;
}



/* 📝 Cabeçalhos */
h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: var(--text-dark);
  text-align: center;
}

/* 🔹 Seções de Informação */
.infoSection {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: #f9f9f9;
}

.infoSection h3 {
  margin-bottom: 10px;
  color: var(--text-dark);
}

.infoSection p {
  margin: 5px 0;
}

/* 📝 Formulários */
.formGroup {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
  color: var(--text-dark);
}

/* Inputs, Select e Textarea */
input,
select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  background: #fff;
}

textarea {
  resize: vertical;
  height: 100px;
}

/* 🟢 Botões */
.button,
.saveButton,
.addButton,
.toggleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Botão de Salvar */
.saveButton {
  background-color: var(--success-color);
}

.saveButton:hover {
  background-color: var(--success-hover);
}

/* Botão de Adicionar */
.addButton {
  margin-top: 10px;
  background-color: var(--success-color);
}

.addButton:hover {
  background-color: var(--success-hover);
}

/* Botão de Alternar Informação */
.toggleButton {
  margin-bottom: 15px;
  background-color: var(--info-color);
}

.toggleButton:hover {
  background-color: var(--info-hover);
}

.toggleButton svg {
  margin-right: 8px;
}

/* 📝 Comentários */
.commentsSection {
  margin-top: 20px;
}

.comment {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.comment p {
  margin: 0;
}

.comment span {
  display: block;
  margin-top: 5px;
  font-size: 0.8rem;
  color: var(--text-light);
}

/* 🚫 Sem Comentários */
.noComments {
  font-style: italic;
  color: var(--text-light);
}