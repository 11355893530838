/* src/pages/OrdensServicos/OrdensServicos.module.css */

/* ======= Layout principal ======= */
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* ======= Botões ======= */
.actionButtonContainer {
  display: flex;
  flex-direction: column;
  /* Botões um abaixo do outro */
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

/* Botão verde (Editar, Ação) */
.actionButton,
.commentButton,
.documentButton {
  background-color: #4CAF50;
  color: white;
  margin: 4px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  transition: opacity 0.3s ease;
  width: 100%;
}

.documentButton {
  background-color: #FF9800;

}

.actionButton:hover {
  opacity: 0.8;
}

.actionButton:disabled {
  background-color: #a5d6a7;
  /* Verde claro para desativado */
  cursor: not-allowed;
  opacity: 0.6;
}

/* Botão azul (Comentários) */
.commentButton {
  background-color: #007BFF;
  color: white;
}

.commentButton:hover {
  opacity: 0.8;
}

.commentButton:disabled {
  background-color: #b3d7ff;
  cursor: not-allowed;
  opacity: 0.6;
}

.documentButton:hover {
  opacity: 0.8;
}

.documentButton:disabled {
  background-color: #ffcc80;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Botão verde (Exportar, Adicionar) */
.exportButton,
.addButton {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  text-decoration: none;
  padding: 10px 20px;
}

.exportButton:hover,
.addButton:hover {
  background-color: #45a049;
}

/* ======= Inputs e Filtros ======= */
.controls {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.searchInput,
.selectInput {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
  min-width: 150px;
}

/* ======= Tabela ======= */
.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px;
  /* Para acomodar melhor as colunas */
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 12px 8px;
  text-align: center;
}

.table th {
  background-color: #f2f2f2;
  cursor: pointer;
}

.table th:hover {
  background-color: #e0e0e0;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #ddd;
}

/* ======= Paginação ======= */
.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.paginationActive a {
  background-color: #4CAF50;
  color: white;
}

.paginationDisabled a {
  color: #ccc;
  cursor: not-allowed;
}

/* ======= Mensagens de erro e sem dados ======= */
.error {
  color: red;
  text-align: center;
  font-weight: bold;
}

.noData {
  text-align: center;
  padding: 20px;
  font-style: italic;
}

/* ======= Seção de impressão ======= */
.printSection {
  display: none;
}

/* Estilos específicos para impressão */
@media print {

  /* Esconde tudo na página */
  body * {
    visibility: hidden;
  }

  /* Exibe apenas o documento de impressão */
  .print-document {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  /* Ajustes visuais para impressão */
  .print-document {
    margin: 0;
    padding: 0;
  }
}