/* src/components/DeviceCard.module.css */
.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 20px; /* Aumentado para deixar mais arredondado */
    padding: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 8px 0;
  }
  
  .icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  
  .label {
    flex-grow: 1;
    margin: 0 12px;
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  
  .deactivateButton {
    background-color: #e74c3c;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .deactivateButton:hover:not(:disabled) {
    background-color: #c0392b;
  }
  
  .deactivateButton:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  