/* Overlay do modal */
.modalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Conteúdo do modal */
  .modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
  }
  
  /* Cabeçalho do modal */
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  /* Botão de fechar */
  .closeButton {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  /* Seção de filtros */
  .filters {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .filterGroup {
    display: flex;
    flex-direction: column;
  }
  
  .filterGroup label {
    font-size: 0.9rem;
    margin-bottom: 4px;
  }
  
  .filterGroup input,
  .filterGroup select {
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Corpo do modal */
  .modalBody {
    font-size: 14px;
  }
  
  /* Estilos para a tabela */
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  /* Mensagem de erro */
  .error {
    color: red;
  }
      