/* src/pages/Auditoria/CriarAuditoriaModal.module.css */

.modal {
    background: white;
    padding: 20px;
    max-width: 800px; /* Aumentamos a largura para acomodar mais campos */
    margin: auto;
    border-radius: 8px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formGroup input,
  .formGroup textarea,
  .formGroup select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .formGroup textarea {
    resize: vertical;
    height: 80px;
  }
  
  /* Seção para Seleção de Postes Auditados */
  .postesAuditados {
    margin-bottom: 20px;
  }
  
  .postesAuditados label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }
  
  .posteItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .posteItem select {
    flex: 2;
  }
  
  .removeButton {
    background-color: #dc3545; /* Vermelho */
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .removeButton:hover {
    opacity: 0.8;
  }
  
  .addPosteButton {
    background-color: #28a745; /* Verde */
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .addPosteButton:hover {
    background-color: #218838;
  }
  
  /* Seção para Adicionar Postes Aleatórios */
  .randomPostesSection {
    margin-bottom: 20px;
  }
  
  .randomPostesSection label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }
  
  .randomPostesControls {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .randomPostesControls input {
    width: 100px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .addRandomPosteButton {
    background-color: #17a2b8; /* Azul */
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .addRandomPosteButton:hover {
    background-color: #138496;
  }
  
  .restrictionNotice {
    margin-top: 5px;
    font-size: 0.9rem;
    color: #dc3545; /* Vermelho para destaque */
  }
  
  /* Seção de Verificações */
  .verificacoesSection {
    margin-bottom: 20px;
  }
  
  .verificacaoPoste {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  
  .verificacaoPoste h4 {
    margin-bottom: 10px;
  }
  
  .verificacaoItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .verificacaoItem label {
    flex: 2;
  }
  
  .verificacaoItem select {
    flex: 1;
  }
  
  .formActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .cancelButton {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .cancelButton:hover {
    background-color: #c82333;
  }
  
  .submitButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #0069d9;
  }
  