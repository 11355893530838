.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalTitle {
  margin: 0;
}

.closeButton {
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.closeButton:hover {
  background-color: #c82333;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
}

.filter label {
  font-size: 1rem;
  margin-bottom: 10px;
}

.filter input,
.filter select,
.filter button {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.filter input,
.filter select {
  flex: 1 1 auto;
  min-width: 150px;
}

.filter button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
  flex: 0 0 auto;
  min-width: 100px;
}

.filter button:hover {
  background-color: #0056b3;
}

.chartContainer {
  margin-top: 20px;
}

.alertMessage {
  color: red;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 20px;
}
