.footer {
  background-color: #16222A;
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
  font-size: 0.9em;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer p {
  margin: 0;
}
