.searchContainer {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Garante que a barra de busca ficará sobre o mapa */
}

.searchInput {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s;
  width: 200px; /* Define uma largura fixa */
}

.searchInput:focus {
  border-color: #007bff;
}

.searchButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.searchButton:hover {
  background-color: #0056b3;
}

.searchIcon {
  font-size: 20px;
  color: #555;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.checkboxLabel input[type='checkbox'] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* Versão Mobile/Tablet - barra compacta ocupando cerca de 30% da largura e alinhada à direita */
@media screen and (max-width: 768px) {
  .searchContainer {
    width: 30%;
    top: 10px;
    right: 10px;
    left: auto;
    /* Mantém o layout horizontal para conservar espaço */
    flex-direction: row;
    gap: 5px;
    padding: 4px;
  }

  .searchInput {
    width: 60%;  /* Ocupa aproximadamente 60% do espaço do container */
    font-size: 12px;
    padding: 4px 6px;
  }

  .searchButton {
    font-size: 12px;
    padding: 4px 6px;
  }

  .searchIcon {
    font-size: 16px;
  }

  /* Opcional: se necessário, o checkbox pode ser ocultado para maior compactação */
  .checkboxLabel {
    display: none;
  }
}
