.postePage {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.header {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.fieldset {
    margin-bottom: 20px;
    
    padding-right: 40px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    
    border: 1px solid #ccc;
    border-radius: 4px;
}

.formGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 30px;
    margin-bottom: 20px;
    align-items: center;
}

.formGroup>div {
    display: flex;
    flex-direction: column;
}

.label {
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 0.9rem;
    color: #555;
    display: flex;
    align-items: center;
}

.label svg {
    margin-right: 5px;
    font-size: 1rem;
    color: #007bff;
}

.input {
    padding: 8px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button {
    padding: 8px 16px;
    font-size: 0.9rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
}

.luminariaItem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.componenteItem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.removeButton {
    background: none;
    border: none;
    color: #d9534f;
    cursor: pointer;
    font-size: 1.2rem;
}

.removeButton:hover {
    color: #c9302c;
}

.colSpan1 {
    grid-column: span 1;
}

.colSpan2 {
    grid-column: span 2;
}

.colSpan3 {
    grid-column: span 3;
}

.imageContainer {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}

.previewImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.removeImageButton {
    background: none;
    border: none;
    color: #d9534f;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 5px 10px;
    background-color: transparent;
    text-decoration: underline;
}

.removeImageButton:hover {
    color: #c9302c;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: white;
    border-radius: 4px;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.closeModalButton {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px;
    font-size: 1rem;
    color: #fff;
    background-color: #d9534f;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.closeModalButton:hover {
    background-color: #c9302c;
}

.confirmButton {
    margin-right: 10px;
    padding: 8px 16px;
    font-size: 0.9rem;
    color: #fff;
    background-color: #d9534f;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirmButton:hover {
    background-color: #c9302c;
}