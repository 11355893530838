/* Importando uma fonte moderna */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

/* Container Principal */
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

/* Container do Formulário e Imagem */
.formContainer {
  background-color: #26314B;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  max-width: 1000px;
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  background: linear-gradient(135deg, #26314B, #0c132c);
}

/* Imagem de Fundo */
.backgroundImage {
  width: 50%;
  height: auto;
  object-fit: cover;
  display: block;
}

/* Conteúdo do Formulário */
.formContent {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 40px 30px;
  box-sizing: border-box;
}

/* Título */
.title {
  color: #ffffff;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 20px;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

/* Logo */
.logo {
  width: 75px;
  margin-bottom: 30px;
}

/* Grupo de Inputs */
.inputGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
}

.icon {
  margin-right: 10px;
  color: #9e9e9e;
  font-size: 1.2rem;
}

.inputField {
  flex: 1;
  padding: 14px;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.inputField:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Grupo de Checkbox */
.checkboxGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.checkboxLabel {
  color: #ffffff;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 0.95rem;
}

.checkbox {
  margin-right: 5px;
  transform: scale(1.2);
}

/* Botão de Login */
.button {
  width: 100%;
  padding: 14px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.button:active {
  transform: translateY(0);
}

/* Wrapper para o Formulário */
.formWrapper {
  width: 100%;
}

/* Estilo específico para o campo de cidade */
.inputCidade {
  width: 80px; /* Largura ajustada para 4 dígitos */
  padding: 14px;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

/* Responsividade para Dispositivos Móveis */
@media (max-width: 768px) {
  .formContainer {
    flex-direction: column;
    max-width: 100%;
    height: auto;
    border-radius: 0;
    background: #26314B;
  }

  .backgroundImage {
    width: 100%;
    height: auto;
    display: none;
  }

  .formContent {
    width: 100%;
    padding: 30px 20px;
    position: static;
    height: auto;
    justify-content: center;
    background-color: #26314B;
    border-radius: 0;
  }

  .title {
    position: static;
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .logo {
    width: 75px;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .inputGroup {
    margin-bottom: 15px;
  }

  .checkboxGroup {
    margin-bottom: 20px;
  }

  .button {
    width: 100%;
  }

  /* Modal Responsivo */
  .modalContainer {
    width: 95%;
    max-width: 90%;
  }

  .inputCidade {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.3rem;
  }

  .inputField {
    padding: 12px;
    font-size: 0.95rem;
  }

  .inputCidade {
    width: 70px; /* Ajustando para telas menores */
  }

  .button {
    padding: 12px;
    font-size: 0.95rem;
  }

  .logo {
    width: 60px;
    margin-bottom: 25px;
  }

  .checkboxLabel {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 1rem;
  }

  /* Ajustes no Modal para telas muito pequenas */
  .modalContainer {
    padding: 15px;
  }

  .closeButton {
    top: 5px;
    right: 5px;
    font-size: 1.2rem;
  }

  .modalContent h3 {
    font-size: 1.2rem;
  }

  .modalContent input {
    font-size: 0.95rem;
    padding: 10px;
  }

  .modalContent button {
    font-size: 0.95rem;
    padding: 10px;
  }
}

/* Estilos do Modal */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
  z-index: 1000;
}

.modalContainer {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-20px);
  animation: slideDown 0.3s ease-in-out;
}

.modalContent {
  margin-top: 10px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
