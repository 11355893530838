/* src/pages/Postes/SubComponents/MapModal.module.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    position: relative;
    background: #fff;
    padding: 20px;
    width: 90%;
    max-width: 600px; /* Reduzir a largura máxima para evitar modals muito grandes */
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .closeModalButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #888;
    transition: color 0.3s ease;
  }
  
  .closeModalButton:hover {
    color: #000;
  }
  
  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .modalTitle {
    margin: 0;
    font-size: 20px;
    text-align: center;
  }
  
  .mapContainer {
    width: 100%;
    height: 300px; /* Reduzir a altura para ajustar ao tamanho do modal */
    border-radius: 8px;
    overflow: hidden;
  }
  
  .directionsButtonContainer {
    display: flex;
    justify-content: center;
  }
  
  .directionsButton {
    background-color: #ff9800; /* Laranja vibrante */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .directionsButton:hover {
    background-color: #e68900;
    transform: translateY(-2px);
  }
  
  .directionsButton:active {
    transform: translateY(0);
  }
  
  @media (max-width: 600px) {
    .modal {
      padding: 15px;
    }
  
    .modalTitle {
      font-size: 18px;
    }
  
    .directionsButton {
      width: 100%;
      justify-content: center;
    }
  }
  