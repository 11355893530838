/* Regioes.module.css */

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .controlsTop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .addButton, .exportButton {
    background-color: #4CAF50; /* Verde */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .addButton:hover, .exportButton:hover {
    background-color: #45a049;
  }
  
  .searchInput {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  
  .table th:hover {
    background-color: #e0e0e0;
  }
  
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tr:hover {
    background-color: #ddd;
  }
  
  .actionButton {
    background-color: #008CBA; /* Azul */
    color: white;
    padding: 6px 12px;
    margin: 0 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: opacity 0.3s ease;
  }
  
  .deleteButton {
    background-color: #f44336; /* Vermelho */
  }
  
  .actionButton:hover {
    opacity: 0.8;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination a {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .pagination a:hover {
    background-color: #f2f2f2;
  }
  
  .paginationActive a {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
  }
  
  .paginationDisabled a {
    color: #ccc;
    cursor: not-allowed;
  }
  