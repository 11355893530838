.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: white;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  max-height: 90vh; /* Limita a altura máxima do modal a 90% da altura da tela */
  overflow-y: auto; /* Permite rolagem vertical se o conteúdo exceder a altura máxima */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.closeModalButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #d9534f;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.closeModalButton:hover {
  background-color: #c9302c;
}

.serviceOrderPage {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.header {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.fieldset {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.formGroup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.formGroup > div {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 0.9rem;
  color: #555;
}

.input {
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 96%;
}

.button {
  padding: 8px 16px;
  font-size: 0.9rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}

.colSpan1 {
  grid-column: span 1;
}

.colSpan2 {
  grid-column: span 2;
}

.colSpan3 {
  grid-column: span 3;
}
