.modal {
    background: white;
    padding: 20px;
    max-width: 800px;
    margin: auto;
    border-radius: 8px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .posteItem {
    margin-bottom: 20px;
  }
  
  .posteItem h3 {
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .posteItem p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .verifyButton {
    background-color: #ffc107;
    color: #212529;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .verifyButton:hover {
    background-color: #e0a800;
  }
  
  .formActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .cancelButton {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancelButton:hover {
    background-color: #c82333;
  }
  
  .submitButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submitButton:hover {
    background-color: #218838;
  }
  