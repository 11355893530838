/* src/components/Tabela/Tabela.module.css */

.tableContainer {
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 12px 8px;
    text-align: center;
}

.table th {
    background-color: #f2f2f2;
    cursor: pointer;
    position: relative;
}

.table th:hover {
    background-color: #e0e0e0;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tr:hover {
    background-color: #ddd;
}