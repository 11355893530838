/* src/components/Dashboard/Dashboard.module.css */

.dashboardContent {
  display: grid;
  grid-template-columns: 1fr; /* Uma coluna por padrão para telas pequenas */
  gap: 10px;
  padding: 10px;
  background-color: #f0f2f5;
  width: 100%; /* Ocupa toda a largura disponível */
  box-sizing: border-box; /* Inclui padding no cálculo da largura */
}

.compact {
  /* Define diferentes números de colunas com base na largura da tela */
  grid-template-columns: 1fr; /* Uma coluna para telas muito pequenas */
}

@media (min-width: 480px) {
  .compact {
    grid-template-columns: repeat(2, 1fr); /* Duas colunas em telas pequenas a médias */
  }
}

@media (min-width: 768px) {
  .compact {
    grid-template-columns: repeat(3, 1fr); /* Três colunas em telas médias */
  }
}

@media (min-width: 1024px) {
  .compact {
    grid-template-columns: repeat(5, 1fr); /* Cinco colunas em telas grandes */
  }
}

/* Media Queries para layout não compacto */

@media (min-width: 768px) {
  .dashboardContent {
    grid-template-columns: repeat(2, 1fr); /* Duas colunas em telas médias */
  }
}

@media (min-width: 1024px) {
  .dashboardContent {
    grid-template-columns: repeat(3, 1fr); /* Três colunas em telas grandes */
  }
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.errorMessage {
  color: #e74c3c;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.retryButton {
  padding: 10px 20px;
  background-color: #3498db;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.retryButton:hover {
  background-color: #2980b9;
}

/* Barra de Carregamento */

.loadingBarContainer {
  position: fixed; /* Fixa a barra no viewport */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px; /* Reduzido de 8px para 6px */
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Garante que a barra fique acima de outros elementos */
}

.loadingBar {
  height: 100%;
  background-color: #3498db; /* Cor da barra */
  transition: width 1s linear;
}

.loadingText {
  position: fixed; /* Fixa o texto no viewport */
  bottom: 14px; /* Ajustado para ficar acima da nova altura da barra */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6); /* Fundo semi-transparente */
  color: #fff;
  padding: 2px 6px; /* Reduzido de 2px 8px para 2px 6px */
  border-radius: 4px;
  font-size: 10px; /* Reduzido de 12px para 10px */
  z-index: 1001; /* Acima da barra de carregamento */
}

/* Ícone de Configuração */

.configIconContainer {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.configIcon {
  font-size: 1.5rem;
  color: #2c3e50;
  cursor: pointer;
  transition: color 0.3s ease;
}

.configIcon:hover {
  color: #2980b9;
}

/* Modal de Configuração */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 80vh;
  overflow-y: auto;
}

.modalContent h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #2c3e50;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #7f8c8d;
}

.closeButton:hover {
  color: #e74c3c;
}

.configSection {
  margin-bottom: 20px;
}

.configSection h3 {
  margin-bottom: 10px;
  color: #34495e;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
}

.checkboxLabel {
  display: flex; /* Utiliza Flexbox para alinhar horizontalmente */
  align-items: center; /* Alinha verticalmente ao centro */
  margin-bottom: 8px;
  font-size: 0.95rem;
  color: #2c3e50;
  cursor: pointer; /* Indica que a label é clicável */
}

.checkboxLabel input {
  margin-right: 8px; /* Espaçamento entre o checkbox e o texto */
  width: 16px; /* Define um tamanho consistente para os checkboxes */
  height: 16px; /* Define um tamanho consistente para os checkboxes */
}

/* Estilos para itens de ordem de gráfico no modal */
.chartOrderItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.orderButtons {
  display: flex;
  flex-direction: column;
}

.orderButton {
  background-color: #ecf0f1;
  border: none;
  border-radius: 4px;
  padding: 4px 6px;
  margin-bottom: 2px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.orderButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.orderButton:hover:not(:disabled) {
  background-color: #bdc3c7;
}
