.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    max-height: 90vh;
    overflow-y: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    outline: none;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.closeModalButton {
    background: none;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.modalContent {
    padding: 20px;
    text-align: left; /* Alinha o texto à esquerda */
}

.modalTitle {
    margin-bottom: 20px;
}

.controlGroup {
    margin-bottom: 15px; /* Reduzido para menos espaço entre os grupos */
    display: flex;
    flex-direction: column; /* Alinha os elementos em coluna */
}

.label {
    margin-bottom: 5px; /* Espaço entre o rótulo e o campo de entrada */
}

.input {
    width: 100%;
    padding: 10px;
    margin-top: 0; /* Remove o espaço extra no topo */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Inclui padding e borda na largura total */
}

.saveButton {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.saveButton:hover {
    background-color: #45a049;
}
