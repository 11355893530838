/* src/components/AlertColorModal/AlertColorModal.module.css */

.modalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 60%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.closeButton {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
}

.form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 500px;

}

.formGroup {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    min-width: 200px;
}

.formGroup label {
    margin-bottom: 5px;
    font-size: 0.9rem;
}

/* Estilização genérica para inputs e selects */
.formGroup input,
.formGroup select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

/* Estilização específica para o input do tipo color */
.formGroup input[type="color"] {
    -webkit-appearance: none;
    border: 1px solid #ccc;
    /* Adiciona o contorno */
    padding: 0;
    width: 50px;
    height: 30px;
}

.formGroup input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

.formGroup input[type="color"]::-webkit-color-swatch {
    border: none;
}

/* Agrupamento horizontal para campos (ex: Dias Início e Dias Fim) */
.horizontalGroup {
    display: flex;
    gap: 20px;
    width: 100%;
}

.horizontalGroup .formGroup {
    flex: 1;
}

.formActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
    margin-top: 20px;
}

.submitButton {
    padding: 8px 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #2980b9;
}

.cancelButton {
    padding: 8px 16px;
    background-color: #ccc;
    color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cancelButton:hover {
    background-color: #bbb;
}