/* Estilos para a Barra de Ferramentas */
.ferramentasBarra {
  position: absolute; /* Fixa o menu em uma posição */
  top: 20px; /* Ajusta a distância do topo da tela */
  left: 20px; /* Ajusta a distância da lateral esquerda */
  background-color: #ffffff; /* Fundo branco para destacar o menu */
  border: 1px solid #ddd; /* Borda leve para delimitar o menu */
  border-radius: 8px; /* Bordas arredondadas */
  padding: 10px; /* Espaçamento interno */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para um efeito flutuante */
  display: flex;
  flex-direction: column; /* Empilha os botões verticalmente */
  align-items: center; /* Centraliza os botões horizontalmente */
  gap: 10px; /* Espaçamento entre os botões */
  z-index: 1000; /* Certifica-se de que está acima do conteúdo principal */
}

.toolButtons {
  display: flex;
  flex-direction: column; /* Empilha os botões */
  align-items: center;
  gap: 10px;
}

.ferramentaBotao {
  background: none;
  border: 1px solid #ddd; /* Adiciona borda leve */
  cursor: pointer;
  padding: 10px;
  border-radius: 50%; /* Botões redondos */
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px; /* Tamanho fixo dos botões */
}

.ferramentaBotao.active {
  background-color: #d0d0d0;
  border-color: #bbb; /* Destaca o botão ativo */
}

.ferramentaBotao:hover {
  background-color: #f0f0f0;
}

/* Contêiner para o botão "Aplicar Seleção" */
.applySelectionContainer {
  top: 100px; /* Ajusta a posição logo abaixo da barra de ferramentas */
  display: flex;
  justify-content: flex-start; /* Alinha o botão à esquerda */
  z-index: 1000;
}

/* Estilo do Botão "Aplicar Seleção" */
.applySelectionButton {
  position: absolute; /* Fixa o menu em uma posição */
  top: 25%; /* Ajusta a distância do topo da tela */
  left: 20px; /* Ajusta a distância da lateral esquerda */
  background-color: #4caf50; /* Verde para ação positiva */
  z-index: 1000;

  color: white;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 8px; /* Bordas arredondadas */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
}

.applySelectionButton:hover {
  background-color: #45a049; /* Cor mais escura ao passar o mouse */
}

/* Estilos para Botões de Ações Poligonais */
.polygonActions {
  display: flex;
  flex-direction: column; /* Empilha as ações poligonais */
  align-items: center;
  gap: 10px;
}

.finishPolygonButton,
.cancelPolygonButton {
  background-color: #008cba; /* Azul para finalizar */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%; /* Botões arredondados */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
}

.finishPolygonButton:hover {
  background-color: #007bb5; /* Tom mais escuro de azul */
}

.cancelPolygonButton {
  background-color: #f44336; /* Vermelho para cancelar */
}

.cancelPolygonButton:hover {
  background-color: #da190b; /* Tom mais escuro de vermelho */
}

/* Responsividade para telas menores */
@media (max-width: 600px) {
  .ferramentasBarra {
    top: 10px;
    left: 10px;
    padding: 8px;
    gap: 8px;
  }

  .ferramentaBotao,
  .finishPolygonButton,
  .cancelPolygonButton {
    width: 35px;
    height: 35px;
    padding: 8px;
  }

  .applySelectionContainer {
    left: 10px; /* Reduz o deslocamento lateral */
    top: 90px; /* Ajusta a posição */
    justify-content: center; /* Centraliza o botão em telas menores */
  }

  .applySelectionButton {
    font-size: 0.9rem; /* Reduz o tamanho da fonte */
    padding: 8px 12px; /* Reduz o tamanho do botão */
  }
}
