.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.controlsTop {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.addButton {
  color: #008CBA;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.addButton:hover {
  color: #005f7a;
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
}

.table th:hover {
  background-color: #e0e0e0;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #ddd;
}

.actionButton {
  background-color: #008CBA;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.actionButton:hover {
  background-color: #005f7a;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  margin: 0 auto;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeModalButton {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.closeModalButton:hover {
  background-color: #d32f2f;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 95%;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancelButton {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton:hover {
  background-color: #d32f2f;
}

.saveButton {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.saveButton:hover {
  background-color: #45a049;
}