/* src/App.css */

/* Configurando o layout flexível com sidebar fixo */
.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

/* O conteúdo ocupará o restante da largura */
.content {
  flex-grow: 1; /* O conteúdo expande para ocupar o espaço disponível */
  overflow-y: auto;
  background-color: #f4f7f9;
  transition: margin-left 0.3s ease-in-out;
}

/* Margem à esquerda para acomodar o Sidebar */
.withSidebar {
  margin-left: 300px; /* Espaço para o Sidebar */
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .content {
    margin-left: 0; /* Remove a margem quando o Sidebar está oculto */
    padding: 0px;
  }
}

/* Regras específicas para impressão */
@media print {
  /* Ocultar todos os elementos */
  body * {
    visibility: hidden;
  }

  /* Mostrar apenas o conteúdo dentro de #printableArea */
  #printableArea, #printableArea * {
    visibility: visible;
  }

  /* Posicionar o conteúdo imprimível no canto superior esquerdo */
  #printableArea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  /* Ajustar estilos da tabela para impressão */
  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th, .table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }

  /* Ajustar tamanhos das imagens */
  .posteImage {
    width: 100px;
    height: 100px;
  }

  /* Ocultar botões de exportação */
  .exportButton {
    display: none;
  }

  /* Ajustar margens, fontes, etc., se necessário */
}
