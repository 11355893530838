/* src/components/ErrorMessage/ErrorMessage.module.css */

.errorContainer {
    padding: 20px;
    background-color: #ffe6e6;
    border: 1px solid #ffcccc;
    border-radius: 5px;
    margin: 20px;
    text-align: center;
  }
  
  .errorText {
    color: #cc0000;
    font-weight: bold;
  }
  