/* src/pages/PosteGrupos/JanelaPostes.module.css */

.janelaPostes {
  position: absolute;
  top: 220px; /* Posicionado abaixo da JanelaGrupos */
  left: 20px;
  width: 300px;
  max-width: 90vw; /* Limita a largura máxima a 90% da largura da viewport */
  min-width: 250px; /* Define uma largura mínima para manter a legibilidade */
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  max-height: 60vh; /* Limita a altura máxima a 60% da altura da viewport */
  min-height: 200px; /* Define uma altura mínima */
  overflow-y: auto;
  
  /* Responsividade */
  @media (max-width: 768px) {
    top: 180px; /* Ajusta a posição para dispositivos móveis */
    right: 10px;
    width: 80vw; /* Aumenta a largura para telas menores */
    max-height: 50vh;
  }

  @media (max-width: 480px) {
    top: 160px;
    right: 5px;
    width: 90vw;
    max-height: 45vh;
  }
}

.title {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.section {
  margin-bottom: 15px;
}

.sectionTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}

.sectionTitle svg {
  margin-right: 5px;
}

.posteList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.posteItem {
  padding: 8px;
  margin-bottom: 5px;
  background-color: #f8f9fa;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 14px;
  color: #333;
}

.posteItem:hover {
  background-color: #e2e6ea;
}

.posteItemSelecionado {
  padding: 8px;
  margin-bottom: 5px;
  background-color: #d1ecf1;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 14px;
  color: #0c5460;
}

.posteItemSelecionado:hover {
  background-color: #bee5eb;
}
