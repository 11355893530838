/* O overlay cobre a tela inteira */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000; /* Z-index alto para ficar sobre todos os elementos */
  }
  
  /* Estilos para o spinner (disco rodando) */
  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.2); /* Borda mais clara */
    border-top: 8px solid #ffffff; /* Borda superior mais escura */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite; /* Animação de rotação */
  }
  
  /* Animação de rotação */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  