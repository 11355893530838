/* src/pages/CadastrosDeSistema/FormatoPostes/FormatoPostes.module.css */

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.controlsTop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.addButton,
.exportButton {
    background-color: #4CAF50;
    /* Verde */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.addButton:hover,
.exportButton:hover {
    background-color: #45a049;
}

.controls {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    /* Melhor alinhamento dos elementos */
}

.searchInput,
.selectInput {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    flex: 1;
    min-width: 150px;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 5px;
}

.tableContainer {
    overflow-x: auto;
}


.actionButton {
    background-color: #008CBA;
    /* Azul */
    color: white;
    padding: 6px 12px;
    margin: 0 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.deleteButton {
    background-color: #f44336;
    /* Vermelho */
}

.activateButton {
    background-color: #4CAF50;
    /* Verde */
}

.actionButton:hover {
    opacity: 0.8;
}

.paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination a {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.pagination a:hover {
    background-color: #f2f2f2;
}

.paginationActive a {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
}

.paginationDisabled a {
    color: #ccc;
    cursor: not-allowed;
}

.noData {
    text-align: center;
    padding: 20px;
    color: #555;
    font-style: italic;
}

/* Adicionando estilos para o botão de ativação */
.activateButton:hover {
    background-color: #45a049;
}


/* Estilos para contêineres de erro e botão de retry */
.errorContainer {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #d9534f;
    border-radius: 4px;
    background-color: #f2dede;
}

.errorMessage {
    color: #a94442;
    margin-bottom: 10px;
}

.retryButton {
    display: flex;
    align-items: center;
    background-color: #f0ad4e;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.retryButton:hover {
    background-color: #ec971f;
}