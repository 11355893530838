/* src/components/Sidebar/Sidebar.module.css */

/* Variáveis CSS */
:root {
    --sidebar-width: 300px;
    --sidebar-bg: #1b2a38;
    --sidebar-color: #ecf0f1;
    --sidebar-hover-bg: #34495e;
    --toggle-color: #ecf0f1;
    --overlay-bg: rgba(0, 0, 0, 0.5);
    --logout-bg: #e74c3c;
    --logout-hover-bg: #c0392b;
    --transition-speed: 0.3s;
    --font-size: 1rem;
    --icon-size: 1.2rem;
}

/* Sidebar Principal */
.sidebar {
    width: var(--sidebar-width);
    background-color: var(--sidebar-bg);
    color: var(--sidebar-color);
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ocupar toda a altura da viewport */
    overflow-y: auto; /* Permitir scroll se o conteúdo exceder a altura */
    z-index: 1000; /* Fica sobre outros elementos */
    flex-shrink: 0; /* Evita que a largura diminua */
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(0); /* Posição padrão */
    transition: transform var(--transition-speed) ease-in-out;
}

/* Classe adicional para quando o Sidebar está aberto em dispositivos móveis */
.open {
    transform: translateX(0); /* Sidebar visível */
}

/* Escondido por padrão em telas menores */
@media (max-width: 768px) {
    .sidebar {
        transform: translateX(-100%); /* Sidebar oculto */
    }

    .open {
        transform: translateX(0); /* Sidebar visível */
    }
}

/* Logo */
.logo {
    text-align: center;
    padding: 20px;
    background-color: #16222A;
    border-bottom: 1px solid #34495e;
}

.logoImage {
    width: 80px;
    height: auto;
}

/* User Info */
.user {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #16222A;
    border-bottom: 1px solid #34495e;
}

.icon {
    margin-right: 10px;
    font-size: var(--icon-size);
}

.userInfo {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.userName {
    font-size: 1.2em;
    font-weight: bold;
    color: #ecf0f1; /* Garante que o nome do usuário seja visível */
}

/* Tooltip Container */
.tooltipContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Nome da Cidade */
.cityName {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #bdc3c7; /* Cor mais sutil para a cidade */
    margin-top: 4px;
}

.cityIcon {
    margin-right: 5px;
    font-size: 1rem; /* Ajuste conforme necessário */
    color: #95a5a6; /* Cor do ícone */
}

/* Tooltip Text */
.tooltipText {
    visibility: hidden;
    width: max-content;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Posiciona acima do elemento */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.8rem;
    white-space: nowrap;
}

/* Tooltip Arrow */
.tooltipText::after {
    content: "";
    position: absolute;
    top: 100%; /* Na parte inferior do tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Mostrar o tooltip ao passar o mouse */
.tooltipContainer:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

/* Navegação */
.nav {
    flex-grow: 1;
    padding-top: 10px;
}

.nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.navItem {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navItem:hover, .subNavItem:hover {
    background-color: var(--sidebar-hover-bg);
}

.link {
    display: flex;
    align-items: center;
    color: var(--sidebar-color);
    text-decoration: none;
    width: 100%;
}

.link:focus {
    outline: 2px solid #ecf0f1;
    outline-offset: -2px;
}

.subMenu {
    list-style: none;
    padding-left: 20px;
    background-color: #2c3e50;
}

.subNavItem {
    padding: 5px 10px;
}

.notification {
    background-color: #e74c3c;
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 0.8rem;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Seção de Logout */
.logoutSection {
    padding: 15px 20px;
    border-top: 1px solid #34495e;
    background-color: #16222A;
}

.logoutButton {
    width: 100%;
    background-color: var(--logout-bg);
    color: white;
    border: none;
    padding: 10px;
    font-size: var(--font-size);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color var(--transition-speed) ease;
}

.logoutButton:hover {
    background-color: var(--logout-hover-bg);
}

.logoutButton .icon {
    margin-right: 8px;
}

/* Botão de Toggle */
.toggleButton {
    display: none; /* Oculto por padrão */
    position: fixed;
    top: 15px;
    left: 15px;
    font-size: 1.5rem;
    color: var(--toggle-color);
    z-index: 1100; /* Acima do Sidebar */
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.toggleButton:focus {
    outline: 2px solid #ecf0f1;
}

/* Overlay */
.overlay {
    display: none; /* Oculto por padrão */
}

/* Exibir o Overlay quando o Sidebar está aberto */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-bg);
    z-index: 900;
}

/* Responsividade */
@media (max-width: 768px) {
    /* Exibir o botão de toggle */
    .toggleButton {
        display: block;
    }

    /* Exibir o Overlay quando o Sidebar está aberto */
    .overlay {
        display: block;
    }

    /* Ajustar z-index do Sidebar quando aberto */
    .sidebar {
        z-index: 1000;
    }
}

/* Scrollbar personalizada (opcional) */
.sidebar::-webkit-scrollbar {
    width: 8px;
}

.sidebar::-webkit-scrollbar-track {
    background: #1b2a38;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #34495e;
    border-radius: 4px;
}
